import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ChannelProvider, useChannel } from 'ably/react';
import { useAtom } from 'jotai';
import _ from 'lodash';
import Lottie from 'lottie-react';
import { motion } from 'motion/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Orders, Rewards, Sellers } from '@waffle/common/src/models';
import { CountryUtil } from '@waffle/common/src/util/country/CountryUtil';
import { PhoneUtil } from '@waffle/common/src/util/phone/PhoneUtil';
import {
  CountryCallingCodePicker,
  OrderSummaryComponent,
} from '@waffle/components-web';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogBody,
  DialogContent,
  HBox,
  Separator,
  Spinner,
  Text,
  TextInput,
  cn,
  useToast,
} from '@waffle/ui-web';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CircleAlertIcon,
  XIcon,
} from '@waffle/ui-web/icons';

import lottieSuccessJson from '../../assets/lottie/95029-success.json';
import ApiService from '../../utils/ApiService';
import {
  orderCustomerMobileNumberAtom,
  useGetOrderQuery,
  useSubdomainSellerQuery,
} from '../../utils/store';

export const OrderSummaryPage = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const ablyChannelName = `public:order=${orderId}`;
  return (
    <ChannelProvider channelName={ablyChannelName}>
      <OrderSummaryMainComponent orderId={orderId} />;
    </ChannelProvider>
  );
};

const OrderSummaryMainComponent = ({ orderId }: { orderId: string }) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const [orderCustomerMobileNumber, setOrderCustomerMobileNumber] = useAtom(
    orderCustomerMobileNumberAtom,
  );

  const [isMemberSignUpSuccessModalOpen, setMemberSignUpSuccessModalOpen] =
    useState<boolean>(false);

  const [countryCode, setCountryCode] = useState<CountryUtil.CountryCode>(
    CountryUtil.CountryCode.SG,
  );
  const [mobileNumberInput, setMobileNumberInput] = useState<string>('');

  const { data: seller } = useSubdomainSellerQuery();
  useEffect(() => {
    setCountryCode(seller.countryCode);
  }, [seller]);

  const { data: order } = useGetOrderQuery({
    orderId: orderId,
    expand: [
      'order.location',
      'order.customer.rewardsMembership',
      'order.seller.logoImage',
    ],
  });

  // Subscribe to the Ably channel
  useChannel(`public:order=${orderId}`, (message) => {
    if (message.name === Orders.OrderUpdatedEvent.TYPE) {
      queryClient.invalidateQueries({
        queryKey: [Orders.Order._type, orderId],
      });
    }
  });

  const [isOrderSummaryExpanded, setOrderSummaryExpanded] =
    useState<boolean>(false);

  const [isErrorTimerExpired, setIsErrorTimerExpired] =
    useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsErrorTimerExpired(true);
    }, 1000 * 30); // 30 secs
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const isOrderFulfilmentTimedOut: boolean = useMemo(() => {
    return (
      isErrorTimerExpired &&
      order.fulfillmentState !== Orders.Order_FulfillmentState.COMPLETED // TODO: Check for ACCEPTED state instead
    );
  }, [order, isErrorTimerExpired]);

  const callingCode = PhoneUtil.getCallingCode(countryCode);
  const mobileNumber = `${callingCode}${mobileNumberInput}`;

  const { mutate: doMemberSignUp, isPending: isMemberSignUpPending } =
    useMutation({
      mutationFn: async ({
        mobileNumber,
        seller,
        order,
      }: {
        mobileNumber: string;
        seller: Sellers.Seller;
        order: Orders.Order;
      }) => {
        // Check if this person is a member already
        const { customer } = await ApiService.request({
          method: 'GET',
          url: `/sellers/${seller?.id}/customers/mobile_number/${mobileNumber}`,
          params: {
            expand: ['customer.rewardsMembership'],
          },
          // Don't throw error on 400+ errors
          validateStatus: (status) => {
            return status < 500;
          },
        });
        const isExistingMember: boolean =
          !!customer && !!customer.rewardsMembership;

        // If this person is already a RewardsMember, this simply sets the order.customerId to the current member
        await ApiService.request({
          method: 'POST',
          url: `/sellers/${seller.id}/orders/${order.id}/create_rewards_membership`,
          data: {
            customerMapping: {
              mobileNumber: mobileNumber,
            },
          },
        });

        if (isExistingMember) {
          toast.show({
            status: 'error',
            title: `You are already a member of ${seller.name} :)`,
            description: `We've attached your membership to this Order. Enjoy your meal!`,
          });
          return;
        } else {
          setMemberSignUpSuccessModalOpen(true);
        }
      },
      onSuccess: async () => {
        // refetchOrders();
        // wait for invalidation to finish - reference: https://tkdodo.eu/blog/mastering-mutations-in-react-query#awaited-promises
        await queryClient.invalidateQueries({
          queryKey: [Orders.Order._type, orderId],
        });
      },
      onError: () => {
        toast.show({
          status: 'error',
          title: 'Failed to join Rewards Programme',
          description: 'Please approach a staff member or try again later',
        });
      },
    });
  useEffect(() => {
    if (!order.customer && !!orderCustomerMobileNumber) {
      doMemberSignUp(
        {
          mobileNumber: orderCustomerMobileNumber,
          seller: seller,
          order: order,
        },
        {
          onSettled: () => {
            setOrderCustomerMobileNumber('');
          },
        },
      );
    }
  }, [order, seller]);

  const handleMemberSignUp = () => {
    if (
      !PhoneUtil.isValidMobileNumber({
        text: mobileNumber,
        countryCode: countryCode,
      })
    ) {
      toast.show({
        status: 'error',
        title: 'Invalid Mobile Number',
        description: `Please enter a valid ${CountryUtil.getCountryConfig(countryCode).name} mobile number.`,
      });
      return;
    }
    doMemberSignUp({
      mobileNumber: mobileNumber,
      seller: seller,
      order: order,
    });
  };

  const isOrderFulfilmentCompleted =
    order.fulfillmentState === Orders.Order_FulfillmentState.COMPLETED;

  return (
    <Box className={'mx-auto w-full max-w-[600px] flex-1 gap-4 py-4'}>
      {/* Screenshot Alert */}
      <HBox
        className={
          'items-center gap-2 rounded-lg border border-blue-500 bg-blue-50 p-4 text-blue-800'
        }>
        <CircleAlertIcon className={'size-4'} />
        <Box className={'flex-1'}>
          <Text variant={'label'}>
            Please screenshot or stay on this page to verify your order
          </Text>
        </Box>
      </HBox>

      {/*Order Status section*/}
      <Card>
        <CardContent>
          <Box className={'gap-4'}>
            {isOrderFulfilmentCompleted ? (
              <Box>
                <Text variant={'h3'}>Store has accepted your order</Text>
              </Box>
            ) : !isOrderFulfilmentTimedOut ? (
              <Box>
                <Text variant={'h3'}>
                  Waiting for store to accept your order...
                </Text>
              </Box>
            ) : (
              <HBox
                className={
                  'items-center gap-2 rounded-md border border-red-500 bg-red-50 p-4 text-red-800'
                }>
                <CircleAlertIcon className={'align-center size-4'} />
                <Box className={'flex-1'}>
                  <Text variant={'label'}>
                    Your payment was successful but the store could not receive
                    your order. Please approach a staff member for help.
                  </Text>
                </Box>
              </HBox>
            )}

            <Box>
              <HBox className={'items-center justify-between gap-2'}>
                <StatusCircle state={'SUCCESS'} />
                <StatusBarHorizontal
                  state={
                    isOrderFulfilmentTimedOut
                      ? 'DISABLED'
                      : isOrderFulfilmentCompleted
                        ? 'ENABLED'
                        : 'LOADING'
                  }
                />
                <StatusCircle
                  state={
                    isOrderFulfilmentTimedOut
                      ? 'FAILURE'
                      : isOrderFulfilmentCompleted
                        ? 'SUCCESS'
                        : 'INDETERMINATE'
                  }
                />
              </HBox>
              <HBox className={'justify-between'}>
                <Text variant={'label'} textAlign={'left'}>
                  Order Placed
                </Text>

                <Text
                  variant={'label'}
                  textAlign={'right'}
                  color={
                    !isOrderFulfilmentCompleted
                      ? 'background.300'
                      : 'background.600'
                  }>
                  Order Accepted
                </Text>
              </HBox>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {/*Order Summary section*/}
      <Card>
        <CardContent>
          <Text variant={'h3'}>Order Summary</Text>
        </CardContent>
        <Separator />
        <CardContent>
          <Box className={'items-center justify-center py-4'}>
            {!!order.ticketName && !!order.ticketValue ? (
              <>
                <Text variant={'muted'}>{order.ticketName}</Text>
                <Text variant={'h3'}>{order.ticketValue}</Text>
              </>
            ) : (
              <Spinner />
            )}
          </Box>
        </CardContent>
        {isOrderSummaryExpanded && (
          <>
            <Separator />
            <CardContent>
              <OrderSummaryComponent
                order={order as Orders.SaleOrder}
                seller={order.seller}
                location={order.location}
                sellerLogoImage={order.seller.logoImage}
              />
            </CardContent>
          </>
        )}
        <Separator />
        <CardContent className={'p-0'}>
          {isOrderSummaryExpanded ? (
            <Button
              variant={'ghost'}
              onPress={() => setOrderSummaryExpanded(!isOrderSummaryExpanded)}>
              Hide Order Details
              <ChevronUpIcon className={'size-4'} />
            </Button>
          ) : (
            <Button
              variant={'ghost'}
              onPress={() => setOrderSummaryExpanded(!isOrderSummaryExpanded)}>
              Show Order Details
              <ChevronDownIcon className={'size-4'} />
            </Button>
          )}
        </CardContent>
      </Card>

      {/*Rewards section*/}
      {!!seller.rewardsProgramme &&
        !order.customer?.rewardsMembership &&
        !orderCustomerMobileNumber &&
        !isMemberSignUpPending && (
          <Card>
            <CardContent>
              <Box className={'gap-4'}>
                <Box className={'items-start'}>
                  <Text variant={'h3'}>Be a member at {seller.name}</Text>
                  <Text variant={'muted'}>
                    {`Are you a frequent customer? Be a member at ${seller.name} to gain access to exclusive deals!`}
                  </Text>
                </Box>
                <Box className={'gap-2'}>
                  <HBox className={'gap-2'}>
                    <TextInput
                      value={mobileNumberInput}
                      onChangeText={(text) => {
                        if (!PhoneUtil.isValidMobileNumberInput(text)) {
                          return;
                        }
                        setMobileNumberInput(text);
                      }}
                      onSubmitEditing={handleMemberSignUp}
                      placeholder={'Mobile number'}
                      inputMode={'tel'}
                      leftElement={
                        <CountryCallingCodePicker
                          countryCode={countryCode}
                          onCountryCodeChange={setCountryCode}
                        />
                      }
                    />
                    <Button
                      isLoading={isMemberSignUpPending}
                      onPress={handleMemberSignUp}>
                      Join Member
                    </Button>
                  </HBox>
                  <Text variant={'muted-small'}>
                    By entering my phone number, I agree to receive
                    notifications and marketing communications via text.
                    Unsubscribe anytime!
                  </Text>
                </Box>
              </Box>
            </CardContent>
          </Card>
        )}

      {isMemberSignUpSuccessModalOpen && !!seller.rewardsProgramme && (
        <MemberSignUpSuccessModal
          onClose={() => setMemberSignUpSuccessModalOpen(false)}
          rewardsProgramme={seller.rewardsProgramme}
        />
      )}
    </Box>
  );
};

const MemberSignUpSuccessModal = ({
  onClose,
  rewardsProgramme,
}: {
  onClose: () => void;
  rewardsProgramme: Rewards.RewardsProgramme;
}) => {
  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent>
        <DialogBody>
          <Box className={'items-center'}>
            <Lottie
              animationData={lottieSuccessJson}
              loop={false}
              autoplay={true}
              style={{
                height: 128,
                width: 128,
              }}
            />
            <Text variant={'h3'}>
              Welcome to {rewardsProgramme.rewardsProgrammeName}!
            </Text>
            <Text variant={'muted'}>
              Use your phone number in the future to get points and earn
              rewards!
            </Text>
          </Box>
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

const StatusCircle = ({
  state,
}: {
  state: 'INDETERMINATE' | 'SUCCESS' | 'FAILURE';
}) => {
  const backgroundColor = useMemo(() => {
    switch (state) {
      case 'INDETERMINATE': {
        return 'bg-gray-100';
      }
      case 'SUCCESS': {
        return 'bg-blue-500';
      }
      case 'FAILURE': {
        return 'bg-rose-500';
      }
    }
  }, [state]);

  const renderInnerComponent = () => {
    switch (state) {
      case 'SUCCESS': {
        return <CheckIcon className={'text-white'} />;
      }
      case 'FAILURE':
        return <XIcon className={'text-white'} />;
      default: {
        return null;
      }
    }
  };

  return (
    <Box
      className={cn(
        'h-[24px] w-[24px] items-center justify-center rounded-full p-1',
        backgroundColor,
      )}>
      {renderInnerComponent()}
    </Box>
  );
};

const AnimatedProgress = ({
  from, // Out of 100
  to, // Out of 100
  shouldAnimate,
}: {
  from: number;
  to: number;
  shouldAnimate: boolean;
}) => {
  return (
    <div className="h-[6px] flex-1 rounded-full bg-blue-100">
      <motion.div
        className="h-full w-full rounded-full bg-blue-500"
        initial={{ width: `${from}%` }}
        animate={
          shouldAnimate
            ? { width: [`${from}%`, `${to}%`] }
            : { width: `${from}%` }
        }
        transition={
          shouldAnimate
            ? { duration: 2, repeat: Infinity, ease: 'easeInOut' }
            : undefined
        }
      />
    </div>
  );
};

const StatusBarHorizontal = ({
  state,
}: {
  state: 'LOADING' | 'ENABLED' | 'DISABLED';
}) => {
  switch (state) {
    case 'LOADING': {
      return <AnimatedProgress from={0} to={100} shouldAnimate={true} />;
    }
    case 'ENABLED':
      return <AnimatedProgress from={100} to={100} shouldAnimate={false} />;
    case 'DISABLED':
      return <AnimatedProgress from={0} to={0} shouldAnimate={false} />;
  }
};
