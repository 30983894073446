import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import Lottie from 'lottie-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  Common,
  Customers,
  Listings,
  OnlineOrdering,
  Orders,
} from '@waffle/common/src/models';
import { CountryUtil } from '@waffle/common/src/util/country/CountryUtil';
import { PhoneUtil } from '@waffle/common/src/util/phone/PhoneUtil';
import { CountryCallingCodePicker } from '@waffle/components-web';
import {
  Box,
  Button,
  Card,
  CardContent,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  HBox,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  Text,
  TextInput,
  WaffleErrorComponent,
  WaffleLoaderComponent,
  useToast,
} from '@waffle/ui-web';
import { ChevronsUpDownIcon } from '@waffle/ui-web/icons';

import LottieClosed from '../../assets/lottie/51661-closed-tag.json';
import ApiService from '../../utils/ApiService';
import {
  orderAtom,
  orderCustomerMobileNumberAtom,
  selfOrderingLinkIdAtom,
  useLocationsQuery,
  useOrderTypesQuery,
  useSelectedLocation,
  useSelectedOrderType,
  useSelfOrderingLinkQuery,
  useSubdomainSellerQuery,
} from '../../utils/store';

export const LandingPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [order, setOrder] = useAtom(orderAtom);
  const [orderCustomerMobileNumber, setOrderCustomerMobileNumber] = useAtom(
    orderCustomerMobileNumberAtom,
  );
  const [countryCode, setCountryCode] = useState(CountryUtil.CountryCode.SG);
  const [mobileNumberInput, setMobileNumberInput] = useState<string>('');
  const mobileNumber: string = useMemo(
    () => `${PhoneUtil.getCallingCode(countryCode)}${mobileNumberInput}`,
    [countryCode, mobileNumberInput],
  );

  const [isSelectOrderTypeOpen, setIsSelectOrderTypeOpen] = useState(false);

  const { data: seller } = useSubdomainSellerQuery();
  useEffect(() => {
    setCountryCode(seller.countryCode);
  }, [seller]);

  const selectedLocation = useSelectedLocation();
  const selectedOrderType = useSelectedOrderType();

  /**
   * Retrieve fields from query params
   */
  const [searchParams] = useSearchParams();
  const selfOrderingLinkId: string | undefined = searchParams.get('l');
  const [, setSelfOrderingLinkId] = useAtom(selfOrderingLinkIdAtom);
  useEffect(() => {
    setSelfOrderingLinkId(selfOrderingLinkId);
  }, [selfOrderingLinkId]);

  const { data: selfOrderingLink, isError: isSelfOrderingLinkError } =
    useSelfOrderingLinkQuery();
  useEffect(() => {
    if (!!selfOrderingLink) {
      setOrder((order) => {
        return {
          ...order,
          locationId: selfOrderingLink.onlineStore?.locationId,
          orderTypeId: selfOrderingLink.orderTypeId,
          ticketValue: selfOrderingLink.ticketValue,
        };
      });
    }
  }, [selfOrderingLink]);

  useEffect(() => {
    // reset Customer Number in local storage
    setOrderCustomerMobileNumber('');
  }, [setOrderCustomerMobileNumber]);

  const { data: locations, isError: isLocationsError } = useLocationsQuery();

  const { data: orderTypes, isError: isOrderTypesError } = useOrderTypesQuery();

  const proceedToCheckout = ({
    customer,
  }: {
    customer?: Customers.Customer;
  }) => {
    if (!selectedLocation) {
      throw new Error('No Location selected');
    }

    if (!selectedOrderType) {
      throw new Error('No OrderType selected');
    }

    setOrder((order) =>
      Orders.Order.create({
        ...order,

        sellerId: seller.id,
        locationId: selectedLocation.id,
        deviceId: null,
        type: Orders.OrderType.SALE,
        saleNumber: null,
        appSource: Orders.OrderAppSource.SCAN_TO_ORDER,
        gatewaySource: Orders.OrderGatewaySource.STOREFRONT,

        fulfillments: [
          Orders.OrderPickupFulfillment.create({
            shouldApplyToAllLineItems: true,
          }),
        ],

        // Populate fields from OrderType
        orderTypeName: selectedOrderType.name ?? null,
        ticketType: selectedOrderType.ticketType ?? null,
        ticketName: selectedOrderType.ticketName ?? null,

        extraCharges:
          selectedOrderType.presetExtraCharges?.map(
            (extraCharge: Listings.ExtraCharge) =>
              Orders.OrderExtraCharge.fromListingExtraCharge({
                listingExtraCharge: extraCharge,
              }),
          ) ?? [],
        taxes:
          selectedOrderType.presetTaxes?.map((tax: Listings.Tax) =>
            Orders.OrderTax.fromListingTax({ listingTax: tax }),
          ) ?? [],

        customerId: customer?.id,
      }),
    );

    navigate('/checkout');
  };

  const { mutate: doMemberLogin, isPending: isDoMemberLoginPending } =
    useMutation({
      mutationFn: async ({
        sellerId,
        mobileNumber,
      }: {
        sellerId: string;
        mobileNumber: string;
      }) => {
        const { customer } = await ApiService.request({
          method: 'GET',
          url: `/sellers/${sellerId}/customers/mobile_number/${mobileNumber}`,
          params: {
            expand: ['customer.rewardsMembership'],
          },
          validateStatus: (status) => {
            if (status >= 400 && status !== 404) {
              return false;
            }
            return true;
          },
        });

        return !!customer && !!customer.rewardsMembership ? customer : null;
      },
      onSuccess: (customer: Customers.Customer | null) => {
        if (!!customer) {
          proceedToCheckout({ customer: customer });
        } else {
          // not member
          toast.show({
            status: 'success',
            title: `Welcome to ${seller.name}!`,
            description: 'Your membership will be created after payment.',
          });
          setOrderCustomerMobileNumber(mobileNumber);
          proceedToCheckout({});
        }
      },
      onError: (error) => {
        toast.show({
          status: 'error',
          title:
            'Failed to add your mobile number to the order, please try again!',
        });
      },
    });

  const handleMemberLogin = () => {
    if (!seller) {
      throw new Error('Encountered an error');
    }
    if (
      !PhoneUtil.isValidMobileNumber({
        text: mobileNumber,
        countryCode: countryCode,
      })
    ) {
      toast.show({
        status: 'error',
        title: 'Your mobile number is invalid!',
      });
      return;
    }
    doMemberLogin({
      sellerId: seller.id,
      mobileNumber: mobileNumber,
    });
  };

  const isContinueButtonDisabled: boolean = useMemo(
    () =>
      !selectedLocation ||
      !selectedOrderType ||
      (!!selectedOrderType &&
        selectedOrderType?.ticketType === Common.TicketType.TEXT && // Text ticketValue required
        !order.ticketValue),
    [selectedLocation, selectedOrderType, order],
  );

  // We temporarily block access without a SelfOrderingLink
  if (!selfOrderingLinkId) {
    return <WaffleErrorComponent />;
  }

  if (isLocationsError || isOrderTypesError || isSelfOrderingLinkError) {
    return <WaffleErrorComponent />;
  }

  if (
    !seller ||
    !locations ||
    !selfOrderingLink ||
    !selfOrderingLink.onlineStore
  ) {
    // Note: OrderType is only loaded once we've picked the location
    return <WaffleLoaderComponent />;
  }

  const sellingStatus = OnlineOrdering.OnlineStore.getSellingStatus({
    seller: seller,
    location: selfOrderingLink.onlineStore.location,
    scanToOrderStore: selfOrderingLink.onlineStore,
  });

  return (
    <>
      <Box className={'mx-auto w-full max-w-[600px] py-4'}>
        <Box className={'h-24'}></Box>
        {/* Surface */}
        <Card>
          <CardContent
            className={'flex flex-col items-center justify-center gap-4'}>
            {/* Top Section -- Logo + Description */}
            <Box
              className={
                '-mt-[100px] h-[160px] w-[160px] items-center justify-center rounded-xl border bg-white p-1 text-center'
              }>
              {!!seller.logoImage ? (
                <img
                  src={seller.logoImage.url}
                  className={'h-full w-full rounded-lg object-cover'}
                  alt={'seller-logo'}
                />
              ) : (
                <Text variant={'h1'}>{seller.name}</Text>
              )}
            </Box>

            <Box className={'gap-2'}>
              <Box className={'text-center'}>
                <Text variant={'h2'}>{seller.name}</Text>
                <Text>{selectedLocation?.address}</Text>
              </Box>

              {/* Store Description */}
              {!!selfOrderingLink.onlineStore.description && (
                // Respect whitespace in store description
                <Text
                  variant={'muted'}
                  className={'whitespace-pre-wrap text-center'}>
                  {selfOrderingLink.onlineStore.description}
                </Text>
              )}
            </Box>
          </CardContent>

          <Separator />

          {sellingStatus !== 'ACCEPTING_ORDERS' ? (
            <CardContent>
              <StoreClosedScreen />
            </CardContent>
          ) : (
            <>
              <CardContent>
                {/* Middle section -- Order Details */}
                <Box className={'gap-4 py-4'}>
                  <Box>
                    <Text variant={'label'}>Order Type</Text>
                    {!!orderTypes && orderTypes.length > 0 && (
                      <Popover
                        open={isSelectOrderTypeOpen}
                        onOpenChange={() =>
                          setIsSelectOrderTypeOpen(!isSelectOrderTypeOpen)
                        }>
                        <PopoverTrigger asChild>
                          <Button
                            isDisabled={!!order.orderTypeId}
                            variant="outline"
                            role="combobox"
                            aria-expanded={isSelectOrderTypeOpen}
                            className="justify-between">
                            {selectedOrderType.name ?? 'Select Order Type...'}
                            <ChevronsUpDownIcon className="size-4 opacity-50" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-[200px] p-0">
                          <Command>
                            {/* <CommandInput placeholder="Search framework..." /> */}
                            <CommandList>
                              <CommandEmpty>No framework found.</CommandEmpty>
                              <CommandGroup>
                                {orderTypes.map((orderType) => (
                                  <CommandItem
                                    key={orderType.id}
                                    value={orderType.id}
                                    onSelect={() => {
                                      setOrder((order) => ({
                                        ...order,
                                        orderTypeId: orderType.id,
                                      }));
                                      setIsSelectOrderTypeOpen(false);
                                    }}>
                                    {orderType.name}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    )}
                  </Box>

                  {selectedOrderType?.ticketType === Common.TicketType.TEXT && (
                    <Box>
                      <Text variant={'label'}>
                        {selectedOrderType.ticketName}
                      </Text>
                      {/* Autofill this with payload from QR code */}
                      <TextInput
                        isDisabled={!!selfOrderingLink?.ticketValue} // Only allow entering of ticket value e.g. table number if not passed in from url. Otherwise ignore
                        defaultValue={order.ticketValue}
                        onChangeText={(text) => {
                          setOrder((order) => ({
                            ...order,
                            ticketValue: text,
                          }));
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </CardContent>

              <Separator />

              <CardContent>
                <Box className={'gap-2'}>
                  {/* Bottom section -- Continue buttons */}
                  {!!seller.rewardsProgramme && (
                    <>
                      <Box className={'gap-1'}>
                        <Text variant={'muted-small'}>
                          Log in to collect points and redeem rewards!
                        </Text>
                        <HBox className={'gap-2'}>
                          <TextInput
                            value={mobileNumberInput}
                            placeholder={'Mobile number'}
                            inputMode={'tel'}
                            onChangeText={(text) => {
                              if (!PhoneUtil.isValidMobileNumberInput(text)) {
                                return;
                              }
                              setMobileNumberInput(text);
                            }}
                            onSubmitEditing={handleMemberLogin}
                            leftElement={
                              <CountryCallingCodePicker
                                countryCode={countryCode}
                                onCountryCodeChange={setCountryCode}
                              />
                            }
                          />

                          <Button
                            isLoading={isDoMemberLoginPending}
                            isDisabled={isContinueButtonDisabled}
                            onPress={handleMemberLogin}>
                            Log in
                          </Button>
                        </HBox>
                      </Box>

                      <HBox className={'items-center gap-2'}>
                        <Separator className={'flex-1'} />
                        <Text variant={'muted'}>or</Text>
                        <Separator className={'flex-1'} />
                      </HBox>
                    </>
                  )}

                  <Button
                    variant={
                      !!seller.rewardsProgramme ? 'secondary' : 'default'
                    }
                    isDisabled={isContinueButtonDisabled}
                    onPress={() => proceedToCheckout({})}>
                    {!!seller.rewardsProgramme
                      ? 'Continue as Guest'
                      : 'Continue'}
                  </Button>
                </Box>
              </CardContent>
            </>
          )}
        </Card>
      </Box>
    </>
  );
};

const StoreClosedScreen = () => {
  return (
    <Box className={'items-center justify-center'}>
      <Lottie
        animationData={LottieClosed}
        loop={false}
        autoplay={true}
        className={'h-[256px] w-[256px]'}
      />
      <Text variant={'label'} className={'text-center'}>
        This store is currently closed, please check back later!
      </Text>
    </Box>
  );
};
