import { useMutation, useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Orders } from '@waffle/common/src/models';
import { MoneyUtil } from '@waffle/common/src/util/money/MoneyUtil';
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  HBox,
  Pressable,
  Separator,
  Text,
  WaffleErrorComponent,
  WaffleLoaderComponent,
  useToast,
} from '@waffle/ui-web';
import { ChevronLeftIcon, Edit2Icon, EditIcon } from '@waffle/ui-web/icons';

import ApiService from '../../utils/ApiService';
import { orderAtom, useSubdomainSellerQuery } from '../../utils/store';
import { OrderLineItemEditModal } from './OrderLineItemEditModal';

export const ReviewOrderPage = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useAtom(orderAtom);
  const toast = useToast();

  const { data: seller } = useSubdomainSellerQuery();
  const location = useLocation();
  const selectedOrderLineItem: Orders.OrderLineItem | undefined =
    location.state?.selectedOrderLineItem;

  const {
    data: calculatedOrder,
    isPending: isCalculatedOrderPending,
    isError: isCalculatedOrderError,
  } = useQuery({
    queryKey: ['order', 'calculate', order], // re-calculate whenever order reference changes
    queryFn: async () => {
      const { order: calculatedOrder } = await ApiService.request({
        method: 'POST',
        url: `/sellers/${seller.id}/orders/calculate`,
        data: {
          order: order,
        },
      });
      return calculatedOrder;
    },
  });

  const { mutate: createOrder, isPending: isCreateOrderPending } = useMutation({
    mutationFn: async () => {
      const { order: resOrder } = await ApiService.request({
        method: 'POST',
        url: `/sellers/${seller.id}/orders`,
        data: {
          order: order,
        },
      });
      if (!resOrder) {
        throw new Error('Order was not successfully created');
      }

      const res = await ApiService.request({
        method: 'POST',
        url: `/sellers/${seller.id}/orders/${resOrder.id}/pay`,
        data: {
          payment: {
            currency: seller.defaultCurrencyCode,
          },
        },
      });
      if (!res.stripe?.clientSecret) {
        throw new Error('No client secret found');
      }

      navigate(
        `/orders/${resOrder.id}/pay?payment_intent_client_secret=${res.stripe.clientSecret}`,
      );
    },
    onError: () => {
      toast.show({
        status: 'error',
        title: 'Failed to place your Order',
        description: 'Please approach a staff member to continue ordering',
      });
    },
  });

  const { mutate: createFreeOrder, isPending: isCreateFreeOrderPending } =
    useMutation({
      mutationFn: async () => {
        if (order.totalAmount !== 0 || order.lineItems.length === 0) {
          throw new Error('Invalid free Order');
        }
        const orderWithTender: Orders.Order = Orders.Order.upsertOrderTender(
          order,
          Orders.OrderCashTender.create({
            cashDetails: {
              tenderAmount: 0,
              changeAmount: 0,
              roundingAmount: 0,
            },
          }),
        );
        const { order: resOrder } = await ApiService.request({
          method: 'POST',
          url: `/sellers/${seller.id}/orders`,
          data: {
            order: orderWithTender,
          },
        });
        if (!resOrder) {
          throw new Error('Order was not successfully created');
        }

        navigate(`/orders/${resOrder.id}`);
      },
      onError: () => {
        toast.show({
          status: 'error',
          title: 'Failed to place your Order',
          description: 'Please approach a staff member to continue ordering',
        });
      },
    });

  // If they've removed all the items
  if (order.lineItems.length < 1) {
    navigate('/checkout', { replace: true });
    return null;
  }

  if (isCalculatedOrderError) {
    return <WaffleErrorComponent />;
  }

  if (isCalculatedOrderPending) {
    return <WaffleLoaderComponent />;
  }

  return (
    <>
      <Box className={'mx-auto w-full max-w-[600px] flex-1 gap-2'}>
        <HBox>
          <Button variant={'ghost'} onPress={() => navigate(-1)}>
            <ChevronLeftIcon className={'size-4'} />
            Add more items
          </Button>
        </HBox>
        <Box className={'flex-1'}>
          <Card>
            <CardContent>
              {/* Header */}
              <Box className={'gap-2'}>
                <Text variant={'h2'}>Review your order</Text>
                <Separator />
              </Box>

              {/* Order details */}
              <Box>
                <Box className={'gap-2 py-2'}>
                  {calculatedOrder.lineItems.map(
                    (lineItem: Orders.OrderLineItem) => (
                      <Pressable
                        key={lineItem.id}
                        className={'hover:bg-background-50 rounded-lg p-2'}
                        onPress={() =>
                          navigate('/checkout/review', {
                            state: { selectedOrderLineItem: lineItem },
                          })
                        }>
                        {/* line item */}
                        <HBox className={'justify-between gap-2'}>
                          <Box>
                            <Badge className={'w-8'}>
                              {lineItem.quantity}x
                            </Badge>
                          </Box>

                          <Text variant={'label'} className={'flex-1'}>
                            {lineItem.name}{' '}
                            {lineItem.selectedItemVariationName !== 'Default'
                              ? '(' + lineItem.selectedItemVariationName + ')'
                              : ''}
                          </Text>
                          <Text variant={'label'} className={'w-20 text-right'}>
                            {MoneyUtil.formatCurrency({
                              amount: lineItem.quantityPrice,
                              currencyCode: seller.defaultCurrencyCode,
                            })}
                          </Text>
                        </HBox>
                        {/* line item add ons */}
                        {lineItem.addOns.map((addOn: Orders.OrderAddOn) => (
                          <HBox
                            key={addOn.id}
                            className={'justify-between gap-2'}>
                            <Box className={'w-8'} />
                            <Text variant={'muted'} className={'flex-1'}>
                              {addOn.quantity}x {addOn.name}
                            </Text>
                            <Text
                              variant={'muted'}
                              className={'w-20 text-right'}>
                              {MoneyUtil.formatCurrency({
                                amount: addOn.totalAmount,
                                currencyCode: seller.defaultCurrencyCode,
                              })}
                            </Text>
                          </HBox>
                        ))}
                        {/* LineItem discounts */}
                        {lineItem.discounts.map(
                          (discount: Orders.OrderDiscount) => (
                            <HBox
                              key={discount.id}
                              className={'justify-between gap-2'}>
                              <Box className={'w-8'} />
                              <Text variant={'muted'} className={'flex-1'}>
                                {discount.name}
                              </Text>
                              <Text
                                variant={'muted'}
                                className={'w-20 text-right'}>
                                {MoneyUtil.formatCurrency({
                                  amount: discount.amount,
                                  currencyCode: seller.defaultCurrencyCode,
                                })}
                              </Text>
                            </HBox>
                          ),
                        )}
                        {/* LineItem note */}
                        <HBox className={'gap-2'}>
                          <Box className={'w-8'} />
                          <Text variant={'muted'}>{lineItem.note}</Text>
                        </HBox>
                        <HBox className={'gap-2'}>
                          <Box className={'w-8'} />
                          <HBox className={'items-center gap-1'}>
                            <Edit2Icon className={'size-2'} />
                            <Text variant={'label'} className={'text-xs'}>
                              Edit
                            </Text>
                          </HBox>
                        </HBox>
                      </Pressable>
                    ),
                  )}
                </Box>

                <Separator />

                <Box className={'gap-2 p-2'}>
                  <HBox className={'justify-between'}>
                    <Text variant={'label'} className={'flex-1'}>
                      Subtotal
                    </Text>
                    <Text variant={'label'} className={'w-20 text-right'}>
                      {MoneyUtil.formatCurrency({
                        amount: calculatedOrder.subtotalAmount,
                        currencyCode: seller.defaultCurrencyCode,
                      })}
                    </Text>
                  </HBox>
                  {calculatedOrder.discounts.map(
                    (discount: Orders.OrderDiscount) => (
                      <HBox key={discount.id} className={'justify-between'}>
                        <Text variant={'muted'} className={'flex-1'}>
                          {discount.name}
                        </Text>
                        <Text variant={'muted'} className={'w-20 text-right'}>
                          {MoneyUtil.formatCurrency({
                            amount: discount.amount,
                            currencyCode: seller.defaultCurrencyCode,
                          })}
                        </Text>
                      </HBox>
                    ),
                  )}
                  {calculatedOrder.extraCharges.map(
                    (extraCharge: Orders.OrderExtraCharge) => (
                      <HBox key={extraCharge.id} className={'justify-between'}>
                        <Text variant={'muted'} className={'flex-1'}>
                          {extraCharge.name}{' '}
                          {extraCharge.isLineItemPricingInclusive
                            ? '(inclusive)'
                            : ''}
                        </Text>
                        <Text variant={'muted'} className={'w-20 text-right'}>
                          {MoneyUtil.formatCurrency({
                            amount: extraCharge.amount,
                            currencyCode: seller.defaultCurrencyCode,
                          })}
                        </Text>
                      </HBox>
                    ),
                  )}
                  {calculatedOrder.taxes.map((tax: Orders.OrderTax) => (
                    <HBox key={tax.id} className={'justify-between'}>
                      <Text variant={'muted'} className={'flex-1'}>
                        {tax.name}{' '}
                        {tax.isLineItemPricingInclusive ? '(inclusive)' : ''}
                      </Text>
                      <Text variant={'muted'} className={'w-20 text-right'}>
                        {MoneyUtil.formatCurrency({
                          amount: tax.amount,
                          currencyCode: seller.defaultCurrencyCode,
                        })}
                      </Text>
                    </HBox>
                  ))}
                </Box>

                <Separator />

                <Box className={'gap-2 p-2'}>
                  <HBox className={'justify-between'}>
                    <Text variant={'label'} className={'flex-1'}>
                      Total
                    </Text>
                    <Text variant={'label'} className={'w-20 text-right'}>
                      {MoneyUtil.formatCurrency({
                        amount: calculatedOrder.totalAmount,
                        currencyCode: seller.defaultCurrencyCode,
                      })}
                    </Text>
                  </HBox>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>

        {/*Footer*/}
        <Box className={'sticky bottom-0 p-2'}>
          <Button
            size={'lg'}
            isDisabled={calculatedOrder.lineItems.length === 0}
            onPress={() => {
              if (calculatedOrder.lineItems.length === 0) {
                // Invalid case, since a) we redirect user away from this page when there are no more line items, or b) don't allow navigation to this page if there are no line items
                throw new Error('There are no OrderLineItem in this Order');
              } else if (calculatedOrder.totalAmount === 0) {
                createFreeOrder();
              } else {
                createOrder();
              }
            }}
            isLoading={isCreateOrderPending || isCreateFreeOrderPending}>
            Continue to Pay{' '}
            {MoneyUtil.formatCurrency({
              amount: calculatedOrder.totalAmount,
              currencyCode: seller.defaultCurrencyCode,
            })}
          </Button>
        </Box>
      </Box>

      {selectedOrderLineItem && (
        <OrderLineItemEditModal
          orderLineItem={selectedOrderLineItem}
          onSave={(orderLineItem: Orders.OrderLineItem) => {
            setOrder(Orders.Order.upsertOrderLineItem(order, orderLineItem));
            navigate(-1);
          }}
          onClose={() => navigate(-1)}
          onRemove={(orderLineItemId) => {
            setOrder(
              Orders.Order.removeOrderLineItemById(order, orderLineItemId),
            );
            navigate(-1);
          }}
        />
      )}
    </>
  );
};
