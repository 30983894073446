import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeError } from '@stripe/stripe-js/types/stripe-js/stripe';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import {
  useBlocker,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogBody,
  DialogContent,
  HBox,
  Spinner,
  Text,
} from '@waffle/ui-web';
import { ChevronLeftIcon } from '@waffle/ui-web/icons';

import EnvConfig from '../../utils/EnvConfig';

const stripePromise = loadStripe(EnvConfig.STRIPE_PUBLISHABLE_KEY); // Instantiate once only

export const PaymentPage = () => {
  const navigate = useNavigate();
  const { orderId } = useParams<{ orderId: string }>();
  const [searchParams] = useSearchParams();
  const clientSecret: string | undefined = searchParams.get(
    'payment_intent_client_secret',
  );

  // No client secret is provided, the user probably reached this page via an invalid path
  // Redirect to the start of ordering flow
  if (!clientSecret) {
    navigate('/checkout', { replace: true });
    return null;
  }

  return (
    <Elements
      options={{
        clientSecret: clientSecret,
        appearance: {
          theme: 'stripe',
        },
      }}
      stripe={stripePromise}>
      <StripeForm
        onBack={() => {
          navigate(-1);
        }}
        // Stripe will redirect to the following url on payment success:
        // https://subdomain.order.wafflepos.com/orders/67648586-2e95-457e-8574-ba9a03995e45
        // ?payment_intent=pi_3MHLyiEYxe7xsCph18r7F4ca
        // &payment_intent_client_secret=pi_3MHLyiEYxe7xsCph18r7F4ca_secret_lw2NoFNZ0iGoxfdg3dC1R2O4g
        // &redirect_status=succeeded
        returnUrl={`${window.location.origin}/orders/${orderId}`}
      />
    </Elements>
  );
};

const StripeForm = ({
  onBack,
  returnUrl,
}: {
  onBack: () => void;
  returnUrl: string;
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const canSubmitPayment: boolean = !!stripe && !!elements;

  const { mutate: handleSubmit, isPending: isHandleSubmitPending } =
    useMutation({
      mutationFn: async (): Promise<void> => {
        if (!canSubmitPayment) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const result: never | { error: StripeError } =
          await stripe.confirmPayment({
            elements,
            confirmParams: {
              // If payment is successful, Stripe will always redirect to the `return_url`
              return_url: returnUrl,
            },
          });

        if (result.error) {
          throw new Error(result.error.message);
        }
      },
    });

  // Don't let the user back out of this page when a payment is processing
  useBlocker(isHandleSubmitPending);

  return (
    <>
      <Box className={'mx-auto w-full max-w-[600px] flex-1 gap-2'}>
        {/*Header*/}
        <HBox>
          <Button variant={'ghost'} onPress={() => onBack()}>
            <ChevronLeftIcon />
            Review order
          </Button>
        </HBox>
        <Box className={'flex-1'}>
          <Card>
            <CardContent>
              {/* {!!handleSubmitError && (
                <HBox
                  className={
                    'items-center gap-2 rounded-md border border-red-500 bg-red-50 p-4 text-red-800'
                  }>
                  <CircleAlertIcon className={'align-center size-4'} />
                  <Box className={'flex-1'}>
                    <Text variant={'label'}>
                      {handleSubmitError.message ??
                        `An error occurred when processing your payment. You have not
                been charged, please try again.`}
                    </Text>
                  </Box>
                </HBox>
              )} */}

              <PaymentElement
                options={{
                  layout: {
                    type: 'accordion',
                  },
                  paymentMethodOrder: ['apple_pay', 'google_pay'], // ApplePay and GooglePay options always come first
                }}
              />
            </CardContent>
          </Card>
        </Box>

        {/*Footer*/}
        <Box className={'sticky bottom-0 p-2'}>
          <Button
            size={'lg'}
            isLoading={isHandleSubmitPending}
            disabled={!canSubmitPayment}
            onPress={() => handleSubmit()}>
            Submit
          </Button>
        </Box>
      </Box>
      {isHandleSubmitPending && (
        <Dialog open={true} onOpenChange={() => {}}>
          <DialogContent shouldRenderDefaultCloseButton={false}>
            <DialogBody className={'gap-2'}>
              <Spinner />
              <Text>
                We are processing your payment, please stay on this page.
              </Text>
            </DialogBody>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
