import React from 'react';
import {
  Navigate,
  Route,
  createRoutesFromElements,
  useParams,
} from 'react-router-dom';

import { Orders } from '@waffle/common/src/models';
import { WaffleErrorComponent, WaffleLoaderComponent } from '@waffle/ui-web';

import { AppLayout } from './pages/AppLayout';
import { CheckoutLayout } from './pages/checkout/CheckoutLayout';
import { CheckoutPage } from './pages/checkout/CheckoutPage';
import { ReviewOrderPage } from './pages/checkout/ReviewOrderPage';
import { LandingPage } from './pages/landing/LandingPage';
import { OrderSummaryPage } from './pages/order/OrderSummaryPage';
import { PaymentPage } from './pages/payment/PaymentPage';
import { useGetOrderQuery, useSubdomainSellerQuery } from './utils/store';

const GuardSellerSubdomain = ({ children }: { children: React.ReactNode }) => {
  const { data: seller, isError: isSellerError } = useSubdomainSellerQuery();

  if (isSellerError) {
    return <WaffleErrorComponent />;
  }

  if (!seller) {
    return <WaffleLoaderComponent />;
  }
  return children;
};

const RedirectToLandingPageIfOrderCompleted = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { orderId } = useParams<{ orderId: string }>();
  const { data: order } = useGetOrderQuery({
    orderId: orderId,
  });
  if (order?.status !== Orders.OrderState.OPEN) {
    return <Navigate to="/" />;
  }
  return children;
};

export const routes = createRoutesFromElements(
  <Route
    element={
      <GuardSellerSubdomain>
        <AppLayout />
      </GuardSellerSubdomain>
    }>
    <Route index element={<LandingPage />} />
    <Route path={'/checkout'} element={<CheckoutLayout />}>
      <Route index element={<CheckoutPage />} />
      <Route path={'/checkout/review'} element={<ReviewOrderPage />} />
    </Route>
    <Route path={'/orders/:orderId'}>
      <Route index element={<OrderSummaryPage />} />
      <Route
        path={'/orders/:orderId/pay'}
        element={
          <RedirectToLandingPageIfOrderCompleted>
            <PaymentPage />
          </RedirectToLandingPageIfOrderCompleted>
        }
      />
    </Route>
  </Route>,
);
