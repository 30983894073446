import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import _ from 'lodash';
import { useMemo } from 'react';

import {
  Customers,
  Listings,
  OnlineOrdering,
  Orders,
  Rewards,
  Sellers,
} from '@waffle/common/src/models';

import ApiService from './ApiService';

export const orderAtom = atom<Partial<Orders.Order>>({});
export const selfOrderingLinkIdAtom = atom<string>('');
export const orderCustomerMobileNumberAtom = atomWithStorage<string>(
  'orderCustomerMobileNumberAtom',
  '',
);

export const useSelfOrderingLinkQuery = (
  additionalQueryOptions?: UseQueryOptions<OnlineOrdering.SelfOrderingLink>,
) => {
  const { data: seller } = useSubdomainSellerQuery();
  const [selfOrderingLinkId] = useAtom(selfOrderingLinkIdAtom);

  return useQuery<OnlineOrdering.SelfOrderingLink>({
    queryKey: ['sellers', seller?.id, 'self_ordering_link', selfOrderingLinkId],
    queryFn: async () => {
      const { selfOrderingLink } = await ApiService.request({
        method: 'GET',
        url: `/sellers/${seller.id}/self_ordering_link/${selfOrderingLinkId}`,
        params: {
          expand: ['selfOrderingLink.onlineStore'],
        },
      });
      if (!selfOrderingLink || !selfOrderingLink.onlineStore) {
        throw new Error('Failed to retrieve SelfOrderingLink');
      }
      return selfOrderingLink;
    },
    staleTime: Infinity, // Don't refetch this query automatically, we don't want to trigger the onSuccess hook multiple times and we expect SelfOrderingLinks to never be updated (unless the linkId changes)
    enabled: !!seller && !!selfOrderingLinkId,
    ...additionalQueryOptions,
  });
};

// We just use the expanded selfOrderingLink.onlineStore property to avoid an additional roundtrip
export const useOnlineStoreQuery =
  (): UseQueryResult<OnlineOrdering.OnlineStore> => {
    const { data: selfOrderingLink, ...rest } = useSelfOrderingLinkQuery();

    return {
      data: selfOrderingLink.onlineStore,
      ...rest,
    } as UseQueryResult<OnlineOrdering.OnlineStore>;
  };

export const useLocationsQuery = (
  additionalQueryOptions?: UseQueryOptions<Sellers.Location[]>,
) => {
  const { data: seller } = useSubdomainSellerQuery();

  return useQuery<Sellers.Location[]>({
    queryKey: [`sellers`, seller?.id, `locations`],
    queryFn: async () => {
      const { locations } = await ApiService.request({
        method: 'GET',
        url: `/sellers/${seller.id}/locations`,
      });
      if (!locations) {
        throw new Error('Failed to retrieve locations');
      }
      return locations;
    },
    staleTime: 1000 * 60 * 10, // 10 mins
    ...additionalQueryOptions,
    enabled:
      !!seller &&
      (!!additionalQueryOptions ? additionalQueryOptions.enabled : true),
  });
};

export const useOrderTypesQuery = (
  additionalQueryOptions?: UseQueryOptions<Listings.OrderType[]>,
) => {
  const { data: seller } = useSubdomainSellerQuery();
  const selectedLocation = useSelectedLocation();

  return useQuery<Listings.OrderType[]>({
    queryKey: ['sellers', seller?.id, 'locations', selectedLocation?.id],
    queryFn: async () => {
      const { orderTypes } = await ApiService.request({
        method: 'GET',
        url: `/sellers/${seller.id}/order_types`,
        params: {
          locationId: selectedLocation.id,
          expand: ['orderTypes.presetExtraCharges', 'orderTypes.presetTaxes'],
        },
      });
      if (!orderTypes) {
        throw new Error('Failed to retrieve orderTypes');
      }
      return orderTypes;
    },
    staleTime: 1000 * 60 * 10, // 10 mins
    ...additionalQueryOptions,
    enabled:
      !!seller &&
      !!selectedLocation &&
      (!!additionalQueryOptions ? additionalQueryOptions.enabled : true),
  });
};

export const useExpandedCategoriesQuery = (
  additionalQueryOptions?: UseQueryOptions<Listings.Category[]>,
) => {
  const { data: seller } = useSubdomainSellerQuery();
  const { data: onlineStore } = useOnlineStoreQuery();
  const selectedLocation = useSelectedLocation();
  const selectedOrderType = useSelectedOrderType();

  return useQuery<Listings.Category[]>({
    queryKey: [
      '/categories',
      { expand: ['categories.items.addOnSets', 'categories.items.image'] },
    ],
    queryFn: async () => {
      return (
        await ApiService.request({
          method: 'GET',
          url: `/sellers/${seller.id}/categories`,
          params: {
            expand: ['categories.items.addOnSets', 'categories.items.image'],
          },
        })
      ).categories;
    },
    select: (categories) => {
      const sanitizedExpandedCategories: Listings.Category[] = _(categories)
        // Filter Category available for this Location
        .filter(
          (category) =>
            category.presentAtAllLocations ||
            category.sellerLocationIds.includes(selectedLocation.id),
        )
        .map((category) => {
          return {
            ...category,
            items: _(category.items)
              // Filter Items available for this Location
              .filter(
                (item) =>
                  item.presentAtAllLocations ||
                  item.sellerLocationIds.includes(selectedLocation.id),
              )
              // Filter items available for this OrderType
              .filter(
                (item: Listings.Item) =>
                  !selectedOrderType || // Ignore filtering if no OrderType is selected
                  item.isAvailableForAllOrderTypes ||
                  item.availableForOrderTypeIds.includes(selectedOrderType.id),
              )
              .map((item) => {
                return {
                  ...item,
                  variations: _(item.variations)
                    // Filter variations available for this Location
                    .filter(
                      (variation) =>
                        variation.presentAtAllLocations ||
                        variation.sellerLocationIds.includes(
                          selectedLocation.id,
                        ),
                    )
                    // Filter variations available for this OrderType
                    .filter(
                      (variation) =>
                        !selectedOrderType || // Ignore filtering if no OrderType is selected
                        variation.isAvailableForAllOrderTypes ||
                        variation.availableForOrderTypeIds.includes(
                          selectedOrderType.id,
                        ),
                    )
                    // Disallow variable priced items
                    .filter((itemVariation) => !itemVariation.isVariable)
                    .value(),
                  addOnSets: _(item.addOnSets)
                    // Filter AddOnSets available for this location
                    .filter(
                      (addOnSet: Listings.AddOnSet) =>
                        addOnSet.presentAtAllLocations ||
                        addOnSet.sellerLocationIds.includes(
                          selectedLocation.id,
                        ),
                    )
                    // Filter AddOnSets available for this OrderType
                    .filter(
                      (addOnSet: Listings.AddOnSet) =>
                        !selectedOrderType || // Ignore filtering if no OrderType is selected
                        addOnSet.isAvailableForAllOrderTypes ||
                        addOnSet.availableForOrderTypeIds.includes(
                          selectedOrderType.id,
                        ),
                    )
                    .map((addOnSet: Listings.AddOnSet) => {
                      return {
                        ...addOnSet,
                        addOns: _(addOnSet.addOns)
                          // Filter AddOnSet.addOns available for this Location
                          .filter(
                            (addOn: Listings.AddOn) =>
                              addOn.presentAtAllLocations ||
                              addOn.sellerLocationIds.includes(
                                selectedLocation.id,
                              ),
                          )
                          // Filter AddOnSet.addOns available for this OrderType
                          .filter(
                            (addOn: Listings.AddOn) =>
                              !selectedOrderType || // Ignore filtering if no OrderType is selected
                              addOn.isAvailableForAllOrderTypes ||
                              addOn.availableForOrderTypeIds.includes(
                                selectedOrderType.id,
                              ),
                          )
                          .value(),
                      };
                    })
                    // Remove AddOnSets that have no more AddOns
                    .filter(
                      (addOnSet: Listings.AddOnSet) =>
                        addOnSet.addOns.length > 0,
                    )
                    .value(),
                };
              })
              // Filter Items still have ItemVariations
              .filter((item) => item.variations.length > 0)
              .value(),
          };
        })
        // Filter Categories that still have items
        .filter((category) => category.items.length > 0)
        .value();

      // Structure according to OnlineStore
      return _(sanitizedExpandedCategories)
        .filter(
          (category) =>
            onlineStore.listings.isAllCategoriesIncluded ||
            !!onlineStore.listings.categories.find(
              (x) => x.categoryId === category.id,
            ),
        )
        .map((category) => {
          const onlineStoreCategory = onlineStore.listings.categories.find(
            (x) => x.categoryId === category.id,
          );
          return {
            ...category,
            items: _(category.items)
              .filter(
                (item) =>
                  onlineStore.listings.isAllCategoriesIncluded ||
                  !!onlineStoreCategory?.isAllItemsIncluded ||
                  !!onlineStoreCategory?.items.find(
                    (x) => x.itemId === item.id,
                  ),
              )
              .map((item) => {
                const onlineStoreItem = onlineStoreCategory?.items.find(
                  (x) => x.itemId === item.id,
                );

                return {
                  ...item,
                  variations: _(item.variations)
                    .filter(
                      (itemVariation) =>
                        onlineStore.listings.isAllCategoriesIncluded ||
                        !!onlineStoreCategory?.isAllItemsIncluded ||
                        !!onlineStoreItem?.isAllItemVariationsIncluded ||
                        !!onlineStoreItem?.itemVariations.find(
                          (x) => x.itemVariationId === itemVariation.id,
                        ),
                    )
                    .orderBy((itemVariation: Listings.ItemVariation) => {
                      const onlineStoreIndex = _.findIndex(
                        onlineStoreItem?.itemVariations,
                        (x) => x.itemVariationId === itemVariation.id,
                      );
                      const originalIndex = _.findIndex(
                        item.variations,
                        (x) => x.id === itemVariation.id,
                      );
                      // Order by onlineStoreIndex, then by original position in the item
                      return onlineStoreIndex >= 0
                        ? onlineStoreIndex
                        : 100 + originalIndex;
                    })
                    .value(),
                  addOnSets: _(item.addOnSets)
                    .filter(
                      (addOnSet) =>
                        onlineStore.listings.isAllAddOnSetsIncluded ||
                        !!onlineStore.listings.addOnSets.find(
                          (onlineStoreAddOnSet) =>
                            onlineStoreAddOnSet.addOnSetId === addOnSet.id,
                        ),
                    )
                    .map((addOnSet) => {
                      const onlineStoreAddOnSet =
                        onlineStore.listings.addOnSets.find(
                          (onlineStoreAddOnSet) =>
                            onlineStoreAddOnSet.addOnSetId === addOnSet.id,
                        );
                      return {
                        ...addOnSet,
                        addOns: _(addOnSet.addOns)
                          .filter(
                            (addOn) =>
                              onlineStore.listings.isAllAddOnSetsIncluded ||
                              !!onlineStoreAddOnSet?.isAllAddOnsIncluded ||
                              !!onlineStoreAddOnSet?.addOns.find(
                                (onlineStoreAddOn) =>
                                  onlineStoreAddOn.addOnId === addOn.id,
                              ),
                          )
                          .orderBy((addOn) => {
                            const onlineStoreIndex = _.findIndex(
                              onlineStoreAddOnSet?.addOns,
                              (onlineStoreAddOn) =>
                                onlineStoreAddOn.addOnId === addOn.id,
                            );
                            const originalIndex = _.findIndex(
                              addOnSet.addOns,
                              (x) => x.id === addOn.id,
                            );
                            // Order by onlineStoreIndex, then by original position in the addOnSet
                            return onlineStoreIndex >= 0
                              ? onlineStoreIndex
                              : 100 + originalIndex;
                          })
                          .value(),
                      };
                    })
                    .filter((addOnSet) => addOnSet.addOns.length > 0)
                    .orderBy((addOnSet) => {
                      const onlineStoreIndex = _.findIndex(
                        onlineStore.listings.addOnSets,
                        (onlineStoreAddOnSet) =>
                          onlineStoreAddOnSet.addOnSetId === addOnSet.id,
                      );
                      const originalIndex = _.findIndex(
                        item.addOnSets,
                        (x) => x.id === addOnSet.id,
                      );
                      // Order by onlineStoreIndex, then by original position in the item
                      return onlineStoreIndex >= 0
                        ? onlineStoreIndex
                        : 100 + originalIndex;
                    })
                    .value(),
                };
              })
              .filter((item) => item.variations.length > 0)
              .orderBy((item: Listings.Item) => {
                const onlineStoreIndex = _.findIndex(
                  onlineStoreCategory?.items,
                  (onlineStoreItem) => onlineStoreItem.itemId === item.id,
                );
                // Order by onlineStoreIndex, then by name
                return onlineStoreIndex >= 0 ? onlineStoreIndex : item.name;
              })

              .value(),
          };
        })
        .filter((category) => category.items.length > 0)
        .orderBy((category: Listings.Category) => {
          const onlineStoreIndex = _.findIndex(
            onlineStore.listings.categories,
            (onlineStoreCategory) =>
              onlineStoreCategory.categoryId === category.id,
          );
          // Order by onlineStoreIndex, then by name
          return onlineStoreIndex >= 0 ? onlineStoreIndex : category.name;
        })
        .value();
    },
    ...additionalQueryOptions,
    enabled:
      !!seller &&
      !!onlineStore &&
      !!selectedLocation &&
      !!selectedOrderType &&
      (!!additionalQueryOptions ? additionalQueryOptions.enabled : true),
  });
};

export const useItemsMap = (): Record<string, Listings.Item> => {
  const { data: categories } = useExpandedCategoriesQuery();

  return useMemo(() => {
    if (!categories) {
      return {};
    }

    return _(categories)
      .flatMap((category) => category.items)
      .keyBy((item) => item.id)
      .value();
  }, [categories]);
};

export const useSelectedLocation = (): Sellers.Location | undefined => {
  const [order] = useAtom(orderAtom);
  const { data: locations } = useLocationsQuery();

  const selectedLocation: Sellers.Location | undefined = useMemo(() => {
    if (!order || !order.locationId || !locations) {
      return undefined;
    }

    return locations?.find((location) => location.id === order.locationId);
  }, [order, locations]);

  return selectedLocation;
};

export const useSelectedOrderType = (): Listings.OrderType | undefined => {
  const [order] = useAtom(orderAtom);
  const { data: orderTypes } = useOrderTypesQuery();

  const selectedOrderType: Listings.OrderType | undefined = useMemo(() => {
    if (!order || !order.locationId || !order.orderTypeId || !orderTypes) {
      return undefined;
    }

    return orderTypes.find((orderType) => orderType.id === order.orderTypeId);
  }, [order, orderTypes]);

  return selectedOrderType;
};

export const useSubdomainSellerQuery = () => {
  const host: string = window.location.hostname;

  const subdomain: string | undefined = useMemo(() => {
    const parts: string[] = host.split('.');
    if (parts.length === 0) {
      return undefined;
    }
    return parts[0];
  }, [host]);

  return useSuspenseQuery<Sellers.Seller>({
    queryKey: ['sellers', 'find', subdomain],
    queryFn: async () => {
      if (!subdomain) {
        throw new Error('Url does not contain Seller slug');
      }
      const { sellers } = await ApiService.request({
        method: 'GET',
        url: '/business/sellers',
        params: {
          slug: subdomain,
          expand: ['sellers.logoImage', 'sellers.rewardsProgramme'],
        },
      });

      if (sellers.length !== 1) {
        throw new Error('Could not find Seller');
      }
      return sellers[0];
    },
  });
};

export const useRewardsProgrammeQuery = () => {
  const sellerQueryResult = useSubdomainSellerQuery();

  return useMemo(() => {
    return {
      ...sellerQueryResult,
      data: sellerQueryResult.data.rewardsProgramme,
    };
  }, [sellerQueryResult]);
};

export const useCustomerQuery = () => {
  const { data: seller } = useSubdomainSellerQuery();
  const [order] = useAtom(orderAtom);

  return useQuery<Customers.Customer>({
    queryKey: ['seller', seller?.id, 'customers', order?.customerId],
    queryFn: async () => {
      if (!seller) {
        throw new Error('No Seller');
      }
      if (!order?.customerId) {
        throw new Error('No customerId in Order');
      }
      const { customer } = await ApiService.request({
        method: 'GET',
        url: `/sellers/${seller.id}/customers/${order.customerId}`,
        params: {
          expand: ['customer.rewardsMembership', 'customer.customerSets'],
        },
      });
      return customer;
    },
    staleTime: 1000 * 60 * 10, // 10 mins
    enabled: !!seller && !!order?.customerId,
  });
};

export const useRewardsMembershipQuery = () => {
  const customerQueryResult = useCustomerQuery();

  return useMemo(() => {
    return {
      ...customerQueryResult,
      data: customerQueryResult.data.rewardsMembership,
    };
  }, [customerQueryResult]);
};

export const useIssuedRewardsQuery = () => {
  const { data: seller } = useSubdomainSellerQuery();
  const { data: customer } = useCustomerQuery();

  return useQuery<Rewards.IssuedReward[]>({
    queryKey: [
      'rewardsProgramme',
      seller.rewardsProgramme.id,
      'rewardsMembership',
      customer?.rewardsMembership.id,
    ],
    queryFn: async () => {
      if (!seller?.rewardsProgramme) {
        throw new Error('No Seller');
      }
      if (!customer?.rewardsMembership) {
        throw new Error('No customerId in Order');
      }
      const { issuedRewards } = await ApiService.request({
        method: 'GET',
        url: `/rewards_programmes/${seller.rewardsProgramme.id}/rewards_memberships/${customer.id}/issued_rewards`,
      });
      return issuedRewards;
    },
    enabled: !!seller?.rewardsProgramme && !!customer?.rewardsMembership,
  });
};

export const useRewardsListingsQuery = () => {
  const { data: seller } = useSubdomainSellerQuery();

  return useQuery<Rewards.RewardsListing[]>({
    queryKey: [
      'rewardsProgramme',
      seller.rewardsProgramme.id,
      'rewardsListings',
    ],
    queryFn: async () => {
      if (!seller?.rewardsProgramme) {
        throw new Error('No Seller');
      }
      const { rewardsListings } = await ApiService.request({
        method: 'GET',
        url: `/rewards_programmes/${seller?.rewardsProgramme.id}/rewards_listings`,
      });
      return rewardsListings;
    },
    enabled: !!seller?.rewardsProgramme,
  });
};

export const useGetOrderQuery = ({
  orderId,
  expand,
}: {
  orderId: string;
  expand?: string[];
}) => {
  const { data: seller } = useSubdomainSellerQuery();
  return useSuspenseQuery<Orders.Order>({
    queryKey: [
      Orders.Order._type,
      orderId,
      {
        expand: expand,
      },
    ],
    queryFn: async (): Promise<Orders.Order> => {
      const res = await ApiService.request({
        method: 'GET',
        url: `/sellers/${seller.id}/orders/${orderId}`,
        params: {
          expand: expand,
        },
      });
      return res.order;
    },
  });
};
