import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useSelectedLocation, useSelectedOrderType } from '../../utils/store';

export const CheckoutLayout = () => {
  const navigate = useNavigate();
  const selectedLocation = useSelectedLocation();
  const selectedOrderType = useSelectedOrderType();

  if (!selectedLocation || !selectedOrderType) {
    navigate('/', { replace: true });
    return null;
  }

  return <Outlet />;
};
