import { useAtom } from 'jotai';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import { Listings, Orders } from '@waffle/common/src/models';
import { MoneyUtil } from '@waffle/common/src/util/money/MoneyUtil';
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFloatingCloseButton,
  DialogFooter,
  HBox,
  Pressable,
  RadioIndicator,
  Separator,
  Text,
  Textarea,
  cn,
} from '@waffle/ui-web';

import {
  orderAtom,
  useItemsMap,
  useSelectedLocation,
  useSubdomainSellerQuery,
} from '../../utils/store';

export const OrderLineItemEditModal = ({
  orderLineItem,
  onSave,
  onRemove,
  onClose,
}: {
  orderLineItem: Orders.OrderLineItem;
  onSave: (orderLineItem: Orders.OrderLineItem) => void;
  onClose: () => void;
  onRemove?: (orderLineItemId: string) => void;
}) => {
  const { data: seller } = useSubdomainSellerQuery();
  const selectedLocation = useSelectedLocation();
  const [order] = useAtom(orderAtom);
  const [modalOrderLineItem, setModalOrderLineItem] =
    useState<Orders.OrderLineItem>(_.cloneDeep(orderLineItem));
  const [modalOrderAddOns, setModalOrderAddOns] = useState<Orders.OrderAddOn[]>(
    modalOrderLineItem.addOns,
  );
  const [modalNote, setModalNote] = useState<string>(
    modalOrderLineItem.note ?? '',
  );
  const debouncedSetModalNote = useCallback(
    _.debounce(setModalNote, 200, {
      trailing: true,
    }),
    [setModalNote],
  );

  const itemsMap: Record<string, Listings.Item> = useItemsMap();
  const selectedItem: Listings.Item | undefined = useMemo(
    () => itemsMap[orderLineItem.itemId],
    [orderLineItem, itemsMap],
  );
  const isLineItemInCart: boolean = useMemo(
    () =>
      !!order.lineItems.find((lineItem) => lineItem.id === orderLineItem.id),
    [orderLineItem, order],
  );

  const canSaveLineItem: boolean = useMemo(() => {
    if (modalOrderLineItem.quantity <= 0) {
      return false;
    }

    for (const addOnSet of selectedItem?.addOnSets ?? []) {
      const qtyOrderAddOnsForThisAddOnSet: number = _(modalOrderAddOns)
        .filter((orderAddOn) => orderAddOn.addOnSetId === addOnSet.id)
        .sumBy((orderAddOn) => orderAddOn.quantity);

      if (
        qtyOrderAddOnsForThisAddOnSet < addOnSet.minQuantity ||
        qtyOrderAddOnsForThisAddOnSet > (addOnSet.maxQuantity ?? Infinity)
      ) {
        return false;
      }
    }
    return true;
  }, [modalOrderAddOns, selectedItem]);

  if (!selectedItem) {
    throw new Error('Selected item not found');
  }

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent shouldRenderDefaultCloseButton={false}>
        <DialogFloatingCloseButton className={'bg-secondary p-1'} />
        <DialogBody className={'p-0'}>
          {!!selectedItem.image && (
            <img
              className={'aspect-video w-full object-cover'}
              alt={selectedItem.name + ' image'}
              src={selectedItem.image.url}
            />
          )}
          {/* Main body of the dialog */}
          <Box className={'bg-background p-4'}>
            {/* Item Details */}
            <Box className={'py-4'}>
              <Text variant={'h1'}>{selectedItem.name}</Text>
              {!!selectedItem.description && (
                <Text variant={'muted'}>{selectedItem.description}</Text>
              )}
            </Box>

            <Separator />

            <Box className={'gap-6 py-8'}>
              {selectedItem.variations.length > 1 && (
                <Box>
                  <HBox className={'items-center justify-between gap-1'}>
                    <Text variant={'h3'}>Variant</Text>
                    <Badge variant={'success'} className={'rounded-full'}>
                      Select one
                    </Badge>
                  </HBox>

                  {selectedItem.variations.map((itemVariation) => {
                    const isSelected: boolean =
                      modalOrderLineItem.itemVariationId === itemVariation.id;
                    const isSellable: boolean =
                      !itemVariation.soldOutAtLocationIds.includes(
                        selectedLocation.id,
                      );
                    return (
                      <Pressable
                        key={itemVariation.id}
                        isDisabled={!isSellable}
                        className={cn(
                          'rounded-md p-2 hover:bg-gray-100 disabled:opacity-50',
                          isSelected ? 'bg-gray-100' : undefined,
                        )}
                        onPress={() => {
                          setModalOrderLineItem(() =>
                            Orders.OrderLineItem.create({
                              ...modalOrderLineItem,
                              itemVariationId: itemVariation.id,
                              selectedItemVariationName: itemVariation.name,
                              price: itemVariation.price,
                            }),
                          );
                        }}>
                        <HBox
                          className={'w-full items-baseline justify-between'}>
                          <Text>{itemVariation.name}</Text>
                          <HBox className={'items-center gap-2'}>
                            <Text variant={'muted'}>
                              {MoneyUtil.formatCurrency({
                                amount: itemVariation.price,
                                currencyCode: seller.defaultCurrencyCode,
                              })}
                            </Text>
                            <RadioIndicator isChecked={isSelected} />
                          </HBox>
                        </HBox>
                      </Pressable>
                    );
                  })}
                </Box>
              )}

              {selectedItem.addOnSetIds.length > 0 && (
                <Box className={'gap-4'}>
                  {selectedItem.addOnSets.map((addOnSet) => {
                    const addOnSetSelectedQuantity = _(modalOrderAddOns)
                      .filter(
                        (orderAddOn: Orders.OrderAddOn) =>
                          orderAddOn.addOnSetId === addOnSet.id,
                      )
                      .sumBy(
                        (orderAddOn: Orders.OrderAddOn) => orderAddOn.quantity,
                      );
                    const isAddOnSetSelectedQuantityValid: boolean =
                      addOnSetSelectedQuantity >= addOnSet.minQuantity &&
                      addOnSetSelectedQuantity <=
                        (addOnSet.maxQuantity ?? Infinity);

                    return (
                      <Box key={addOnSet.id}>
                        <HBox className={'items-baseline justify-between'}>
                          <Text variant={'h3'}>{addOnSet.name}</Text>
                          {addOnSet.minQuantity === addOnSet.maxQuantity ? (
                            <Badge
                              variant={
                                isAddOnSetSelectedQuantityValid
                                  ? 'success'
                                  : 'secondary'
                              }
                              className={'rounded-full'}>
                              {`Select ${addOnSet.minQuantity}`}
                            </Badge>
                          ) : addOnSet.minQuantity === 0 ? (
                            <Badge
                              variant={'secondary'}
                              className={'rounded-full'}>
                              Optional
                              {addOnSet.maxQuantity > 1 &&
                                `, max ${addOnSet.maxQuantity}`}
                            </Badge>
                          ) : (
                            <Badge
                              variant={
                                isAddOnSetSelectedQuantityValid
                                  ? 'success'
                                  : 'secondary'
                              }
                              className={'rounded-full'}>
                              {`Min ${addOnSet.minQuantity}, max ${addOnSet.maxQuantity}`}
                            </Badge>
                          )}
                        </HBox>

                        {addOnSet.addOns.map((addOn) => {
                          const modalOrderAddOn: Orders.OrderAddOn | undefined =
                            modalOrderAddOns.find(
                              (orderAddOn) => orderAddOn.addOnId === addOn.id,
                            );
                          const modalOrderAddOnQuantity: number =
                            modalOrderAddOn?.quantity ?? 0;
                          const isSelected: boolean = !!modalOrderAddOn;
                          const isSellable: boolean =
                            !addOn.soldOutAtLocationIds.find(
                              (locationId) =>
                                locationId === selectedLocation.id,
                            );

                          if (!addOnSet.allowMultiple) {
                            return (
                              <Pressable
                                key={addOn.id}
                                className={cn(
                                  'flex-row items-center rounded-md p-2 hover:bg-gray-100 disabled:opacity-50',
                                  isSelected ? 'bg-gray-100' : undefined,
                                )}
                                isDisabled={!isSellable}
                                onPress={() => {
                                  setModalOrderAddOns((modalOrderAddOns) =>
                                    modalOrderAddOns.filter(
                                      (orderAddOn) =>
                                        orderAddOn.addOnSetId !== addOnSet.id,
                                    ),
                                  );
                                  if (!isSelected) {
                                    setModalOrderAddOns((modalOrderAddOns) => [
                                      ...modalOrderAddOns,
                                      Orders.OrderAddOn.fromListingAddOn({
                                        listingAddOn: addOn,
                                        listingAddOnSet: addOnSet,
                                      }),
                                    ]);
                                  }
                                }}>
                                <Box className={'flex-1'}>
                                  <Text>{addOn.name}</Text>
                                  {!isSellable && (
                                    <Text variant={'muted'}>Unavailable</Text>
                                  )}
                                </Box>
                                <HBox className={'items-center gap-2'}>
                                  {addOn.price > 0 && (
                                    <Text variant={'muted'}>
                                      +
                                      {MoneyUtil.formatCurrency({
                                        amount: addOn.price,
                                        currencyCode:
                                          seller.defaultCurrencyCode,
                                      })}
                                    </Text>
                                  )}

                                  {addOnSet.allowQuantity ? (
                                    <ButtonGroup
                                      variant={'outline'}
                                      className={'size-6'}>
                                      <Button
                                        isDisabled={
                                          !isSellable ||
                                          modalOrderAddOnQuantity === 0
                                        }
                                        onPress={() => {
                                          if (modalOrderAddOnQuantity <= 1) {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.filter(
                                                  (orderAddOn) =>
                                                    orderAddOn.addOnId !==
                                                    addOn.id,
                                                ),
                                            );
                                          } else {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.map((x) => {
                                                  if (
                                                    x.addOnId ===
                                                    modalOrderAddOn.addOnId
                                                  ) {
                                                    return Orders.OrderAddOn.setQuantity(
                                                      x,
                                                      x.quantity - 1,
                                                    );
                                                  } else {
                                                    return x;
                                                  }
                                                }),
                                            );
                                          }
                                        }}>
                                        -
                                      </Button>
                                      <Button isDisabled={true}>
                                        {modalOrderAddOnQuantity}
                                      </Button>
                                      <Button
                                        isDisabled={!isSellable}
                                        onPress={() => {
                                          if (!isSelected) {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.filter(
                                                  (orderAddOn) =>
                                                    orderAddOn.addOnSetId !==
                                                    addOnSet.id,
                                                ),
                                            );
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) => [
                                                ...modalOrderAddOns,
                                                Orders.OrderAddOn.fromListingAddOn(
                                                  {
                                                    listingAddOn: addOn,
                                                    listingAddOnSet: addOnSet,
                                                  },
                                                ),
                                              ],
                                            );
                                          } else {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.map((x) => {
                                                  if (
                                                    x.addOnId ===
                                                    modalOrderAddOn.addOnId
                                                  ) {
                                                    return Orders.OrderAddOn.setQuantity(
                                                      x,
                                                      x.quantity + 1,
                                                    );
                                                  } else {
                                                    return x;
                                                  }
                                                }),
                                            );
                                          }
                                        }}>
                                        +
                                      </Button>
                                    </ButtonGroup>
                                  ) : (
                                    <RadioIndicator isChecked={isSelected} />
                                  )}
                                </HBox>
                              </Pressable>
                            );
                          } else {
                            return (
                              <Pressable
                                key={addOn.id}
                                className={cn(
                                  'flex-row items-center rounded-md p-2 hover:bg-gray-100 disabled:opacity-50',
                                  isSelected ? 'bg-gray-100' : undefined,
                                )}
                                isDisabled={!isSellable}
                                onPress={() => {
                                  if (isSelected) {
                                    setModalOrderAddOns((modalOrderAddOns) =>
                                      modalOrderAddOns.filter(
                                        (orderAddOn) =>
                                          orderAddOn.addOnId !== addOn.id,
                                      ),
                                    );
                                  } else {
                                    setModalOrderAddOns((modalOrderAddOns) => [
                                      ...modalOrderAddOns,
                                      Orders.OrderAddOn.fromListingAddOn({
                                        listingAddOn: addOn,
                                        listingAddOnSet: addOnSet,
                                      }),
                                    ]);
                                  }
                                }}>
                                <Box className={'flex-1'}>
                                  <Text>{addOn.name}</Text>
                                  {!isSellable && (
                                    <Text variant={'muted'}>Unavailable</Text>
                                  )}
                                </Box>
                                <HBox className={'items-center gap-2'}>
                                  {addOn.price > 0 && (
                                    <Text variant={'muted'}>
                                      +
                                      {MoneyUtil.formatCurrency({
                                        amount: addOn.price,
                                        currencyCode:
                                          seller.defaultCurrencyCode,
                                      })}
                                    </Text>
                                  )}

                                  {addOnSet.allowQuantity ? (
                                    <ButtonGroup
                                      variant={'outline'}
                                      className={'size-6'}>
                                      <Button
                                        isDisabled={
                                          !isSellable ||
                                          modalOrderAddOnQuantity === 0
                                        }
                                        onPress={() => {
                                          if (modalOrderAddOnQuantity <= 1) {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.filter(
                                                  (orderAddOn) =>
                                                    orderAddOn.addOnId !==
                                                    addOn.id,
                                                ),
                                            );
                                          } else {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.map((x) => {
                                                  if (
                                                    x.addOnId ===
                                                    modalOrderAddOn.addOnId
                                                  ) {
                                                    return Orders.OrderAddOn.setQuantity(
                                                      x,
                                                      x.quantity - 1,
                                                    );
                                                  } else {
                                                    return x;
                                                  }
                                                }),
                                            );
                                          }
                                        }}>
                                        -
                                      </Button>
                                      <Button isDisabled={true}>
                                        {modalOrderAddOnQuantity}
                                      </Button>
                                      <Button
                                        isDisabled={!isSellable}
                                        onPress={() => {
                                          if (!isSelected) {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) => [
                                                ...modalOrderAddOns,
                                                Orders.OrderAddOn.fromListingAddOn(
                                                  {
                                                    listingAddOn: addOn,
                                                    listingAddOnSet: addOnSet,
                                                  },
                                                ),
                                              ],
                                            );
                                          } else {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.map((x) => {
                                                  if (
                                                    x.addOnId ===
                                                    modalOrderAddOn.addOnId
                                                  ) {
                                                    return Orders.OrderAddOn.setQuantity(
                                                      x,
                                                      x.quantity + 1,
                                                    );
                                                  } else {
                                                    return x;
                                                  }
                                                }),
                                            );
                                          }
                                        }}>
                                        +
                                      </Button>
                                    </ButtonGroup>
                                  ) : (
                                    <Checkbox isChecked={isSelected} />
                                  )}
                                </HBox>
                              </Pressable>
                            );
                          }
                        })}
                      </Box>
                    );
                  })}
                </Box>
              )}
              <Box>
                <HBox className={'items-center justify-between'}>
                  <Text variant={'h3'}>Special Request</Text>
                  <Badge className={'rounded-full'}>Optional</Badge>
                </HBox>
                <Textarea
                  defaultValue={modalNote}
                  onChangeText={debouncedSetModalNote}
                />
              </Box>
            </Box>
          </Box>
        </DialogBody>
        <DialogFooter>
          <HBox className={'w-full gap-2'}>
            <ButtonGroup variant={'outline'}>
              <Button
                isDisabled={
                  modalOrderLineItem.quantity === 0 ||
                  (!isLineItemInCart && modalOrderLineItem.quantity === 1)
                }
                onPress={() => {
                  setModalOrderLineItem(
                    Orders.OrderLineItem.create({
                      ...modalOrderLineItem,
                      quantity: modalOrderLineItem.quantity - 1,
                    }),
                  );
                }}>
                -
              </Button>
              <Button isDisabled={true} className={'rounded-none'}>
                {modalOrderLineItem.quantity}
              </Button>
              <Button
                onPress={() =>
                  setModalOrderLineItem(
                    Orders.OrderLineItem.create({
                      ...modalOrderLineItem,
                      quantity: modalOrderLineItem.quantity + 1,
                    }),
                  )
                }>
                +
              </Button>
            </ButtonGroup>
            {isLineItemInCart &&
            modalOrderLineItem.quantity === 0 &&
            !!onRemove ? (
              <Button
                variant={'outline'}
                className={'flex-1'}
                onPress={() => {
                  onRemove(modalOrderLineItem.id);
                }}>
                Remove from cart
              </Button>
            ) : (
              <Button
                isDisabled={!canSaveLineItem}
                className={'flex-1'}
                onPress={() => {
                  onSave(
                    Orders.OrderLineItem.create({
                      ...modalOrderLineItem,
                      addOns: modalOrderAddOns,
                      note: modalNote || null,
                    }),
                  );
                }}>
                {`${
                  isLineItemInCart ? 'Update' : 'Add to'
                } cart ${MoneyUtil.formatCurrency({
                  amount:
                    modalOrderLineItem.quantity *
                    (modalOrderLineItem.price +
                      _.sumBy(
                        modalOrderAddOns,
                        (orderAddOn) => orderAddOn.quantity * orderAddOn.price,
                      )),
                  currencyCode: seller.defaultCurrencyCode,
                })}`}
              </Button>
            )}
          </HBox>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
