import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import _ from 'lodash';
import Lottie from 'lottie-react';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';

import { Listings, Orders, Rewards } from '@waffle/common/src/models';
import {
  Box,
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  HBox,
  Pressable,
  Tabs,
  TabsList,
  TabsTrigger,
  Text,
  WaffleErrorComponent,
  WaffleLoaderComponent,
  cn,
  useToast,
} from '@waffle/ui-web';
import {
  GiftIcon,
  PercentIcon,
  ShoppingBasketIcon,
  StoreIcon,
} from '@waffle/ui-web/icons';

import PresentLottie from '../../assets/lottie/128316-redeem-voucher.json';
import ApiService from '../../utils/ApiService';
import {
  orderAtom,
  useCustomerQuery,
  useIssuedRewardsQuery,
  useRewardsListingsQuery,
  useRewardsMembershipQuery,
  useRewardsProgrammeQuery,
  useSelectedLocation,
} from '../../utils/store';

enum RewardsSections {
  YOUR_REWARDS = 'your-rewards',
  SHOP_FOR_REWARDS = 'shop-for-rewards',
}

export const RewardsDialog = ({ onClose }: { onClose: () => void }) => {
  const [selectedSection, setSelectedSection] = useState<RewardsSections>(
    RewardsSections.SHOP_FOR_REWARDS,
  );

  const [order, setOrder] = useAtom(orderAtom);

  const rewardInOrder: Orders.OrderLineItem | Orders.OrderDiscount | undefined =
    useMemo(() => {
      return (
        order.lineItems.find(
          (orderLineItem: Orders.OrderLineItem) =>
            !!orderLineItem.rewardsItemId,
        ) ??
        order.discounts.find(
          (orderDiscount: Orders.OrderDiscount) =>
            !!orderDiscount.rewardsDiscountId,
        )
      );
    }, [order]);

  if (!!rewardInOrder) {
    return (
      <Dialog open={true} onOpenChange={onClose}>
        <DialogContent>
          <DialogBody className={'items-center justify-center'}>
            <Lottie
              animationData={PresentLottie}
              size={24}
              loop={1}
              initialSegment={[25, 56]}
            />
            <Text>Enjoy your reward!</Text>
            <Text variant={'h3'}>{rewardInOrder.name}</Text>
          </DialogBody>
        </DialogContent>
      </Dialog>
    );
  }

  if (selectedSection === RewardsSections.SHOP_FOR_REWARDS) {
    return (
      <Dialog open={true} onOpenChange={onClose}>
        <DialogContent>
          <RewardsShopDialog
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
          />
        </DialogContent>
      </Dialog>
    );
  } else if (selectedSection === RewardsSections.YOUR_REWARDS) {
    return (
      <Dialog open={true} onOpenChange={onClose}>
        <DialogContent>
          <YourRewardsDialog
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
          />
        </DialogContent>
      </Dialog>
    );
  }
};

const RewardsShopDialog = ({
  selectedSection,
  setSelectedSection,
}: {
  selectedSection: RewardsSections;
  setSelectedSection: (section: RewardsSections) => void;
}) => {
  const toast = useToast();
  const [order, setOrder] = useAtom(orderAtom);

  const { data: customer } = useCustomerQuery();
  const { data: rewardsProgramme } = useRewardsProgrammeQuery();
  const { data: rewardsMembership } = useRewardsMembershipQuery();
  const location = useSelectedLocation();

  const { data: rewardsListings, isError: isRewardsListingsError } =
    useRewardsListingsQuery();

  /**
   * Only RewardsListings targeted for this Customer's CustomerSet should be visible (whether it's claimable or not)
   * TODO: Shift this filter to the server side
   */
  const visibleRewardsListings: Rewards.RewardsListing[] = useMemo(() => {
    if (!rewardsListings || !customer?.customerSets) {
      return [];
    }

    const customerSetIds = customer.customerSets.map(
      (customerSet) => customerSet.id,
    );
    return rewardsListings.filter(
      (rewardsListing: Rewards.RewardsListing) =>
        rewardsListing.isIssuableToAllCustomers ||
        rewardsListing.customerSetIds.some((id) => customerSetIds.includes(id)),
    );
  }, [rewardsListings, customer]);

  const claimableRewardsListings: Rewards.RewardsListing[] = useMemo(() => {
    if (!visibleRewardsListings || !rewardsMembership || !location) {
      return [];
    }

    return (
      _(visibleRewardsListings)
        // Filter points
        .filter(
          (rewardsListing: Rewards.RewardsListing) =>
            rewardsMembership.currentPoints >= rewardsListing.pointsRequired,
        )
        // Filter location
        .filter(
          (rewardsListing: Rewards.RewardsListing) =>
            rewardsListing.presentAtAllLocations ||
            rewardsListing.sellerLocationIds.includes(location.id),
        )
        // Order by points (ascending)
        .orderBy(
          (rewardsListing: Rewards.RewardsListing) =>
            rewardsListing.pointsRequired,
        )
        .value()
    );
  }, [visibleRewardsListings, rewardsMembership, location]);

  const unclaimableRewardsListings: Rewards.RewardsListing[] = useMemo(() => {
    if (!visibleRewardsListings || !claimableRewardsListings) {
      return [];
    }

    return visibleRewardsListings.filter(
      (rewardsListing) =>
        !claimableRewardsListings.find(
          (claimableRewardsListing) =>
            claimableRewardsListing.id === rewardsListing.id,
        ),
    );
  }, [visibleRewardsListings, claimableRewardsListings]);

  const [selectedRewardsListing, setSelectedRewardsListing] = useState<
    Rewards.RewardsListing | undefined
  >(undefined);

  const { mutate: issueReward, isPending: isIssueRewardPending } = useMutation({
    mutationFn: async (rewardListingToIssue: Rewards.RewardsListing) => {
      const { issuedReward } = await ApiService.request({
        method: 'POST',
        url: `/rewards_programmes/${rewardsProgramme.id}/rewards_listings/${rewardListingToIssue.id}/issue/`,
        data: {
          customerId: rewardsMembership.customerId,
        },
      });
      return issuedReward as Rewards.IssuedReward;
    },
    onSuccess: (issuedReward: Rewards.IssuedReward) => {
      switch (issuedReward.listingSnapshot.rewardsListingType) {
        case Rewards.ListingType.ITEM: {
          const orderLineItem: Orders.OrderLineItem =
            Orders.OrderLineItem.fromIssuedRewardItem({
              rewardsItem: issuedReward.listingSnapshot,
              issuedRewardId: issuedReward.id,
            });
          setOrder((order) =>
            Orders.Order.upsertOrderLineItem(order, orderLineItem),
          );
          break;
        }
        case Rewards.ListingType.DISCOUNT: {
          const orderDiscount: Orders.OrderDiscount =
            Orders.OrderDiscount.fromIssuedRewardDiscount({
              rewardsDiscount: issuedReward.listingSnapshot,
              issuedRewardId: issuedReward.id,
            });
          setOrder((order) =>
            Orders.Order.upsertOrderDiscount(order, orderDiscount),
          );
          break;
        }
        default: {
          // Do nothing
          break;
        }
      }

      toast.show({
        status: 'success',
        title: 'Reward redeemed',
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        title: 'Failed to redeem Reward',
        description: 'Please try again later',
      });
    },
  });

  if (isRewardsListingsError) {
    return <WaffleErrorComponent />;
  }

  if (!rewardsListings) {
    return <WaffleLoaderComponent />;
  }

  if (rewardsListings.length === 0) {
    return (
      <DialogBody className={'items-center justify-center'}>
        <Lottie
          animationData={PresentLottie}
          size={24}
          loop={true}
          initialSegment={[0, 14]}
        />
        <Text variant={'label'}>Stay tuned for more Rewards!</Text>
      </DialogBody>
    );
  }

  return (
    <>
      <DialogHeader className={'gap-2'}>
        <DialogTitle>Rewards</DialogTitle>
        <Tabs
          value={selectedSection}
          onValueChange={(value) => {
            setSelectedSection(value as RewardsSections);
          }}>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger
              value={RewardsSections.SHOP_FOR_REWARDS}
              className={'gap-1'}>
              <StoreIcon className={'size-4'} />
              Rewards shop
            </TabsTrigger>
            <TabsTrigger
              value={RewardsSections.YOUR_REWARDS}
              className={'gap-1'}>
              <GiftIcon className={'size-4'} />
              Your rewards
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </DialogHeader>
      <DialogBody>
        <Box>
          <HBox className={'items-baseline gap-1'}>
            <Text variant={'h2'}>{rewardsMembership.currentPoints} </Text>
            <Text variant={'label'}>{rewardsProgramme.pointName}</Text>
          </HBox>
        </Box>

        <Box>
          {claimableRewardsListings.map(
            (rewardsListing: Rewards.RewardsListing) => (
              <Pressable
                key={rewardsListing.id}
                onPress={() => {
                  setSelectedRewardsListing(rewardsListing);
                }}
                className={cn(
                  'h-[100px] flex-row items-start gap-4 overflow-hidden rounded-lg p-2',
                  selectedRewardsListing?.id === rewardsListing.id &&
                    'bg-gray-200',
                )}>
                {/* Left side -- image / icon */}
                <Box
                  className={
                    'aspect-square h-full items-center justify-center rounded-lg bg-gray-100'
                  }>
                  {rewardsListing.type === Listings.ListingType.ITEM ? (
                    <ShoppingBasketIcon className={'text-gray-500'} />
                  ) : (
                    <PercentIcon className={'text-gray-500'} />
                  )}
                </Box>

                {/* Right side -- Info section */}
                <Box className={'h-full flex-1 justify-between'}>
                  <Box>
                    <Text className={'line-clamp-1 font-semibold'}>
                      {`${rewardsListing.name} ${
                        rewardsListing.rewardsListingType ===
                          Rewards.ListingType.ITEM &&
                        rewardsListing.item.itemVariationName !== 'Default'
                          ? `(${rewardsListing.item.itemVariationName})`
                          : ''
                      }`}
                    </Text>
                    {!!rewardsListing.description && (
                      <Text variant={'muted'} className={'line-clamp-1'}>
                        {rewardsListing.description}
                      </Text>
                    )}
                  </Box>
                  <HBox className={'items-baseline gap-1'}>
                    <Text variant={'label'}>
                      {rewardsListing.pointsRequired}{' '}
                    </Text>
                    <Text variant={'label'}>{rewardsProgramme?.pointName}</Text>
                  </HBox>
                </Box>
              </Pressable>
            ),
          )}
        </Box>
        <Box>
          {unclaimableRewardsListings.map(
            (rewardsListing: Rewards.RewardsListing) => (
              <Pressable
                key={rewardsListing.id}
                isDisabled
                className={cn(
                  'h-[100px] flex-row items-start gap-4 overflow-hidden rounded-lg p-2',
                  selectedRewardsListing?.id === rewardsListing.id &&
                    'bg-gray-200',
                )}>
                {/* Left side -- image / icon */}
                <Box
                  className={
                    'aspect-square h-full items-center justify-center rounded-lg bg-gray-100'
                  }>
                  {rewardsListing.type === Listings.ListingType.ITEM ? (
                    <ShoppingBasketIcon className={'text-gray-500'} />
                  ) : (
                    <PercentIcon className={'text-gray-500'} />
                  )}
                </Box>

                {/* Right side -- Info section */}
                <Box className={'h-full flex-1 justify-between'}>
                  <Box>
                    <Text className={'line-clamp-1 font-semibold'}>
                      {`${rewardsListing.name} ${
                        rewardsListing.rewardsListingType ===
                          Rewards.ListingType.ITEM &&
                        rewardsListing.item.itemVariationName !== 'Default'
                          ? `(${rewardsListing.item.itemVariationName})`
                          : ''
                      }`}
                    </Text>
                    {!!rewardsListing.description && (
                      <Text variant={'muted'} className={'line-clamp-1'}>
                        {rewardsListing.description}
                      </Text>
                    )}
                  </Box>
                  <HBox className={'items-baseline gap-1'}>
                    <Text variant={'label'}>
                      {rewardsListing.pointsRequired}{' '}
                    </Text>
                    <Text variant={'label'}>{rewardsProgramme?.pointName}</Text>
                  </HBox>
                </Box>
              </Pressable>
            ),
          )}
        </Box>
      </DialogBody>
      <DialogFooter>
        <Button
          isLoading={isIssueRewardPending}
          isDisabled={!selectedRewardsListing}
          onPress={() => {
            issueReward(selectedRewardsListing);
          }}>
          {!selectedRewardsListing
            ? 'Redeem'
            : `Redeem for ${selectedRewardsListing.pointsRequired} ${rewardsProgramme.pointName}`}
        </Button>
      </DialogFooter>
    </>
  );
};

const YourRewardsDialog = ({
  selectedSection,
  setSelectedSection,
}: {
  selectedSection: RewardsSections;
  setSelectedSection: (section: RewardsSections) => void;
}) => {
  const selectedLocation = useSelectedLocation();
  const { data: issuedRewards } = useIssuedRewardsQuery();

  const [selectedIssuedReward, setSelectedIssuedReward] = useState<
    Rewards.IssuedReward | undefined
  >(undefined);
  const [order, setOrder] = useAtom(orderAtom);

  const redeemableIssuedRewards = useMemo(
    () =>
      _(issuedRewards)
        .filter(
          (issuedReward) =>
            issuedReward.listingSnapshot.presentAtAllLocations ||
            issuedReward.listingSnapshot.sellerLocationIds.includes(
              selectedLocation.id,
            ),
        )
        // NOTE: We don't filter by customerSetIds here, if the reward has been issued to the Customer, they should be able to redeem it
        // Order by issuedAt (TODO: More correct to orderBy expiresAt instead?)
        .orderBy((issuedReward) => issuedReward.issuedAt, 'desc')
        .value(),
    [selectedLocation, issuedRewards],
  );

  const redeemReward = (issuedReward: Rewards.IssuedReward) => {
    switch (issuedReward.listingSnapshot.rewardsListingType) {
      case Rewards.ListingType.ITEM: {
        const orderLineItem: Orders.OrderLineItem =
          Orders.OrderLineItem.fromIssuedRewardItem({
            rewardsItem: issuedReward.listingSnapshot,
            issuedRewardId: issuedReward.id,
          });
        setOrder((order) =>
          Orders.Order.upsertOrderLineItem(order, orderLineItem),
        );
        break;
      }

      case Rewards.ListingType.DISCOUNT: {
        const orderDiscount: Orders.OrderDiscount =
          Orders.OrderDiscount.fromIssuedRewardDiscount({
            rewardsDiscount: issuedReward.listingSnapshot,
            issuedRewardId: issuedReward.id,
          });
        setOrder((order) =>
          Orders.Order.upsertOrderDiscount(order, orderDiscount),
        );
        break;
      }
    }
  };

  if (redeemableIssuedRewards.length === 0) {
    return (
      <DialogBody className={'items-center justify-center'}>
        <Box className={'items-center justify-center'}>
          <Lottie
            animationData={PresentLottie}
            size={24}
            loop={true}
            initialSegment={[0, 14]}
          />
          <Text>You currently do not own any rewards.</Text>
        </Box>
        <Button
          onPress={() => {
            setSelectedSection(RewardsSections.SHOP_FOR_REWARDS);
          }}>
          Shop for Rewards
        </Button>
      </DialogBody>
    );
  }

  return (
    <>
      <DialogHeader className={'gap-2'}>
        <DialogTitle>Rewards</DialogTitle>
        <Tabs
          value={selectedSection}
          onValueChange={(value) => {
            setSelectedSection(value as RewardsSections);
          }}>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger
              value={RewardsSections.SHOP_FOR_REWARDS}
              className={'gap-1'}>
              <StoreIcon className={'size-4'} />
              Rewards shop
            </TabsTrigger>
            <TabsTrigger
              value={RewardsSections.YOUR_REWARDS}
              className={'gap-1'}>
              <GiftIcon className={'size-4'} />
              Your rewards
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </DialogHeader>
      <DialogBody>
        <Box>
          {redeemableIssuedRewards.map((issuedReward: Rewards.IssuedReward) => (
            <Pressable
              key={issuedReward.id}
              onPress={() => {
                setSelectedIssuedReward(issuedReward);
              }}
              className={cn(
                'h-[100px] flex-row items-start gap-4 overflow-hidden rounded-lg p-2',
                selectedIssuedReward?.id === issuedReward.id
                  ? 'bg-gray-200'
                  : 'enabled:hover:bg-gray-50',
              )}>
              {/* Left side -- image / icon */}
              <Box
                className={
                  'aspect-square h-full items-center justify-center rounded-lg bg-gray-100'
                }>
                {issuedReward.listingSnapshot.rewardsListingType ===
                Rewards.ListingType.ITEM ? (
                  <ShoppingBasketIcon className={'text-gray-500'} />
                ) : (
                  <PercentIcon className={'text-gray-500'} />
                )}
              </Box>
              {/* Right side -- Info section */}
              <Box className={'h-full flex-1'}>
                <Text className={'line-clamp-1 font-semibold'}>
                  {issuedReward.listingSnapshot.name}
                  {issuedReward.listingSnapshot.rewardsListingType ===
                    Rewards.ListingType.ITEM && (
                    <>
                      {' '}
                      {issuedReward.listingSnapshot.item.itemVariationName ===
                      'Default'
                        ? ''
                        : `(${issuedReward.listingSnapshot.item.itemVariationName})`}
                    </>
                  )}
                </Text>
                <Text variant={'muted'} className={'line-clamp-1'}>
                  {issuedReward.listingSnapshot.description}
                </Text>
                {!!issuedReward.expiresAt && (
                  <Text variant={'muted'} className={'line-clamp-1'}>
                    Expires on{' '}
                    {DateTime.fromISO(issuedReward.expiresAt).toLocaleString(
                      DateTime.DATE_MED,
                    )}
                  </Text>
                )}
              </Box>
            </Pressable>
          ))}
        </Box>
      </DialogBody>
      <DialogFooter>
        <Button
          isDisabled={!selectedIssuedReward}
          onPress={() => {
            if (!selectedIssuedReward) {
              return;
            }
            redeemReward(selectedIssuedReward);
          }}>
          Redeem
        </Button>
      </DialogFooter>
    </>
  );
};
