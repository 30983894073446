import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Ably from 'ably';
import { AblyProvider } from 'ably/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { Toaster } from '@waffle/ui-web';
// Styles for Tailwind theme
import '@waffle/ui-web/tailwind.css';

import './css-reset.css';
import { routes } from './routes';
import EnvConfig from './utils/EnvConfig';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const ablyClient = new Ably.Realtime({
  key: EnvConfig.ABLY_API_KEY,
});

// React-router
const router = createBrowserRouter(routes);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    {/* // Inject Query Client for React-query */}
    <QueryClientProvider client={queryClient}>
      <AblyProvider client={ablyClient}>
        <RouterProvider router={router} />
      </AblyProvider>
    </QueryClientProvider>

    {/* Provider for Toasts */}
    <Toaster />
  </React.StrictMode>,
);
