import { useAtom } from 'jotai';
import _ from 'lodash';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { Listings, Orders, Sellers } from '@waffle/common/src/models';
import { MoneyUtil } from '@waffle/common/src/util/money/MoneyUtil';
import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  HBox,
  Pressable,
  Separator,
  Text,
  VirtualList,
  WaffleErrorComponent,
  WaffleLoaderComponent,
} from '@waffle/ui-web';
import {
  CheckIcon,
  ChevronRightIcon,
  ChevronsUpDownIcon,
  GiftIcon,
  PlusIcon,
} from '@waffle/ui-web/icons';

import {
  orderAtom,
  useCustomerQuery,
  useExpandedCategoriesQuery,
  useSelectedLocation,
  useSubdomainSellerQuery,
} from '../../utils/store';
import { OrderLineItemEditModal } from './OrderLineItemEditModal';
import { RewardsDialog } from './RewardsDialog';

type ListHeaderRow = {
  type: 'header';
  data: Listings.Category;
  index: number;
};

type ListDataRow = {
  type: 'data';
  data: Listings.Item;
  index: number;
};

type ListSeparatorRow = {
  type: 'separator';
  data: undefined;
  index: number;
};

type ListRow = ListHeaderRow | ListDataRow | ListSeparatorRow;

export const CheckoutPage = () => {
  const navigate = useNavigate();

  const virtualListRef = useRef<typeof VirtualList<ListRow>>(null);

  const { data: seller } = useSubdomainSellerQuery();
  const { data: customer } = useCustomerQuery();
  const { data: categories, isError: isCategoriesError } =
    useExpandedCategoriesQuery();
  const [order, setOrder] = useAtom(orderAtom);

  const [selectedOrderLineItem, setSelectedOrderLineItem] = useState<
    Orders.OrderLineItem | undefined
  >(undefined);
  const [isRewardsModalOpen, setRewardsModalOpen] = useState<boolean>(false);

  const [isCategorySelectionOpen, setIsCategorySelectionOpen] =
    useState<boolean>(false);

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(0);

  // Map sections into a flat list of items and section headers
  const flatData: ListRow[] = useMemo(() => {
    return _(categories)
      .flatMap((category) => [
        {
          type: 'header' as const,
          data: category,
        },
        ...category.items
          .flatMap((item, index) => [
            {
              type: 'data' as const,
              data: item,
            },
            {
              type: 'separator' as const,
              data: undefined,
            },
          ])
          .slice(0, -1),
      ])
      .map((x, index) => ({ ...x, index: index })) // Add index to each section
      .value();
  }, [categories]);

  const categoryRows = useMemo(() => {
    return _(flatData)
      .filter((item) => item.type === 'header')
      .value();
  }, [flatData]);

  if (isCategoriesError) {
    return <WaffleErrorComponent />;
  }

  if (!categories) {
    return <WaffleLoaderComponent />;
  }

  return (
    <>
      <Box className={'bg-background mx-auto w-full max-w-[600px]'}>
        {/* Category selection */}
        <HBox className={'bg-background sticky top-0 z-10 gap-2 p-2'}>
          <Button
            variant={'secondary'}
            onPress={() => setIsCategorySelectionOpen(!isCategorySelectionOpen)}
            className={
              'flex-1 justify-between truncate rounded-full text-left'
            }>
            {flatData[selectedCategoryIndex].type === 'header'
              ? flatData[selectedCategoryIndex].data.name
              : ''}
            <ChevronsUpDownIcon className={'size-4'} />
          </Button>

          {!!customer?.rewardsMembership && (
            <Button
              variant={'ghost'}
              size={'icon'}
              onPress={() => {
                setRewardsModalOpen(true);
              }}>
              <GiftIcon className={'!size-6 text-violet-600'} />
            </Button>
          )}
        </HBox>

        <ItemsList
          rows={flatData}
          setSelectedCategoryIndex={setSelectedCategoryIndex}
          setSelectedOrderLineItem={setSelectedOrderLineItem}
          virtualListRef={virtualListRef}
        />

        {order.lineItems.length > 0 && (
          <>
            {/*Footer*/}
            <Box
              className={
                'bg-background sticky bottom-0 w-full items-center justify-center p-2'
              }>
              <Button
                size={'lg'}
                className={'w-full px-4 py-2 shadow-lg'}
                onPress={() => navigate('/checkout/review')}>
                <HBox className={'w-full items-center justify-between'}>
                  <HBox className={'flex-1 items-center gap-2'}>
                    <Badge>
                      {_(order.lineItems).sumBy(
                        (orderLineItem) => orderLineItem.quantity,
                      )}
                    </Badge>
                    <span>
                      {MoneyUtil.formatCurrency({
                        amount: order.subtotalAmount,
                        currencyCode: seller.defaultCurrencyCode,
                      })}
                    </span>
                  </HBox>
                  <HBox className={'items-center gap-1'}>
                    <span>Go to cart</span>
                    <ChevronRightIcon className={'size-4'} />
                  </HBox>
                </HBox>
              </Button>
            </Box>
          </>
        )}
      </Box>

      {!!selectedOrderLineItem && (
        <OrderLineItemEditModal
          orderLineItem={selectedOrderLineItem}
          onSave={(orderLineItem: Orders.OrderLineItem) => {
            setOrder(Orders.Order.upsertOrderLineItem(order, orderLineItem));
            setSelectedOrderLineItem(undefined);
          }}
          onClose={() => setSelectedOrderLineItem(undefined)}
        />
      )}

      {!!isRewardsModalOpen && (
        <RewardsDialog
          onClose={() => {
            setRewardsModalOpen(false);
          }}
        />
      )}

      {/* Category selection pop up actionsheet */}
      <Drawer
        open={isCategorySelectionOpen}
        onClose={() => setIsCategorySelectionOpen(false)}>
        <DrawerContent>
          <DrawerBody>
            {categoryRows.map((section: ListRow) => (
              <Pressable
                key={section.data.id}
                className={
                  'w-full flex-row items-center gap-2 rounded-lg p-2 hover:bg-gray-100'
                }
                onPress={() => {
                  virtualListRef.current?.scrollToIndex(section.index, {
                    align: 'top',
                    behavior: 'smooth',
                  });
                  setIsCategorySelectionOpen(false);
                }}>
                {selectedCategoryIndex === section.index ? (
                  <CheckIcon className={'size-4'} />
                ) : (
                  <Box className={'w-4'} />
                )}
                <Text variant={'label'} className={'line-clamp-1 flex-1'}>
                  {section.data.name}
                </Text>
              </Pressable>
            ))}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

/**
 * Extract this component out for memoization
 */
export const ItemsList = React.memo(
  ({
    rows,
    setSelectedCategoryIndex,
    setSelectedOrderLineItem,
    virtualListRef,
  }: {
    rows: ListRow[];
    setSelectedCategoryIndex: Dispatch<SetStateAction<number>>;
    setSelectedOrderLineItem: Dispatch<
      SetStateAction<Orders.OrderLineItem | undefined>
    >;
    virtualListRef: MutableRefObject<typeof VirtualList<ListRow>>;
  }) => {
    const [order] = useAtom(orderAtom);
    const { data: seller } = useSubdomainSellerQuery();
    const selectedLocation: Sellers.Location = useSelectedLocation();

    const renderItem = ({ item, index }: { item: ListRow; index: number }) => {
      if (item.type === 'separator') {
        return <Separator />;
      }

      if (item.type === 'header') {
        return (
          <Box height={'64px'} className={'h-full justify-end p-2'}>
            <Text variant={'h2'} className={'line-clamp-1'}>
              {item.data.name}
            </Text>
          </Box>
        );
      }

      const sellableVariations: Listings.ItemVariation[] =
        item.data.variations.filter(
          (itemVariation) =>
            !itemVariation.soldOutAtLocationIds.find(
              (locationId) => locationId === selectedLocation.id,
            ),
        );
      const isSellable: boolean =
        !item.data.soldOutAtLocationIds.find(
          (locationId) => locationId === selectedLocation.id,
        ) && sellableVariations.length >= 1;

      const checkoutQuantity: number = _(order.lineItems)
        .filter((orderLineItem) => orderLineItem.itemId === item.data.id)
        .sumBy((orderLineItem) => orderLineItem.quantity);

      return (
        <Pressable
          key={item.data.id}
          className={
            'h-[128px] w-full flex-row gap-4 rounded-lg p-3 hover:bg-gray-100'
          }
          isDisabled={!isSellable}
          onPress={() => {
            if (!isSellable) {
              return;
            }
            setSelectedOrderLineItem(
              Orders.OrderLineItem.fromListingItem({
                listingItem: item.data,
                listingItemVariation: sellableVariations[0],
                listingCategory: item.data.category,
              }),
            );
          }}>
          {/* Left side -- Image */}
          {!!item.data.image && (
            <img
              src={item.data.image.thumbnailUrl}
              className={'aspect-square h-full rounded-xl object-contain'}
              alt={item.data.name + ' thumbnail image'}
            />
          )}
          {/* Inner container within padding */}
          <Box className={'relative h-full w-full'}>
            {/* Right side -- Item details */}
            <Box className={'h-full flex-1 justify-between gap-1'}>
              <Box className={'flex-1'}>
                <Text>{item.data.name}</Text>

                {!!item.data.description && (
                  <Text variant={'muted'} className={'line-clamp-2'}>
                    {item.data.description}
                  </Text>
                )}
              </Box>

              <Text variant={'label'}>
                {item.data.variations.length === 1
                  ? MoneyUtil.formatCurrency({
                      amount: item.data.variations[0].price,
                      currencyCode: seller.defaultCurrencyCode,
                    })
                  : `${MoneyUtil.formatCurrency({
                      amount:
                        _.minBy(
                          item.data.variations,
                          (itemVariation: Listings.ItemVariation) =>
                            itemVariation.price,
                        )?.price ?? 0,
                      currencyCode: seller.defaultCurrencyCode,
                    })} - ${MoneyUtil.formatCurrency({
                      amount:
                        _.maxBy(
                          item.data.variations,
                          (itemVariation: Listings.ItemVariation) =>
                            itemVariation.price,
                        )?.price ?? 0,
                      currencyCode: seller.defaultCurrencyCode,
                    })}`}
              </Text>
            </Box>

            {checkoutQuantity > 0 ? (
              <Box
                className={
                  'bg-background absolute bottom-0 right-0 h-6 w-6 items-center justify-center rounded-lg border-2 border-gray-200 p-0'
                }>
                <Text className={'text-xs font-semibold text-gray-500'}>
                  {checkoutQuantity}
                </Text>
              </Box>
            ) : (
              <Box
                className={
                  'bg-primary absolute bottom-0 right-0 h-6 w-6 items-center justify-center rounded-lg p-0'
                }>
                <PlusIcon className={'size-4 text-white'} />
              </Box>
            )}
          </Box>
        </Pressable>
      );
    };

    return (
      <VirtualList
        data={rows}
        renderItem={renderItem}
        estimateSize={(index) => {
          if (rows[index].type === 'separator') {
            return 1;
          }
          if (rows[index].type === 'header') {
            return 64;
          }
          return 128;
        }}
        className="mx-auto w-full max-w-[600px] bg-white"
        ListEmptyComponent={<Text>No items available</Text>}
        onViewableItemsChanged={(viewableItems) => {
          if (viewableItems.length === 0) {
            return;
          }
          if (viewableItems[0].item.type !== 'header') {
            return;
          }
          setSelectedCategoryIndex(viewableItems[0].index);
        }}
        ref={virtualListRef}
      />
    );
  },
);
